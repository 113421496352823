import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  removeFromCart,
  selectTotalQuantity,
  selectTotalPrice,
  decreaseFromCart,
  clearCart,
} from '../../../features/cart/cartSlice';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import MenuItemPopup from '../Menu/MenuItemPopup';
import {
  motion,
  useMotionValue,
  useAnimate,
  useDragControls,
} from 'framer-motion';
import useMeasure from 'react-use-measure';
import { createNewOrder } from '../../../features/orders/ordersThunk';
import { createOrderDetail } from '../../../features/orders/orderDetailsThunk';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const CartPopup = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const totalQuantity = useSelector(selectTotalQuantity);
  const totalPrice = useSelector(selectTotalPrice);

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [menuPopupOpen, setMenuPopupOpen] = useState(false);
  const [isRectangle, setIsRectangle] = useState(false);

  // Add controls for dragging
  const controls = useDragControls();
  const y = useMotionValue(0);
  const [scope, animate] = useAnimate();
  const [drawerRef, { height }] = useMeasure();

  const toggleCartPopup = () => {
    setOpen((prev) => !prev);
  };

  const handleButtonClick = () => {
    toggleCartPopup();
    if (!isRectangle) {
      setIsRectangle(true);
    }
  };

  useEffect(() => {
    if (cartItems.length > 0 && !open) {
      setOpen(false);
    }
  }, [cartItems, open]);

  const handleCloseCart = useCallback(async () => {
    const yStart = typeof y.get() === 'number' ? y.get() : 0;
    if (isMobile) {
      await animate(
        '#cart-popup',
        {
          y: [yStart, height],
        },
        {
          duration: 0.3,
          ease: 'easeOut',
        }
      );
    } else {
      await animate(
        '#cart-popup',
        {
          opacity: [1, 0],
        },
        {
          duration: 0.2,
          ease: 'easeOut',
        }
      );
    }
    setOpen(false);
  }, [animate, height, isMobile, y]);

  useEffect(() => {
    if (cartItems.length === 0 && open) {
      handleCloseCart();
    }
  }, [cartItems.length, open, handleCloseCart]);

  const handleOpenMenuItemPopup = (item) => {
    setSelectedItem(item);
    setMenuPopupOpen(true);
  };

  const handleCloseMenuItemPopup = () => {
    setMenuPopupOpen(false);
  };

  const handleIncreaseQuantity = (uniqueId) => {
    const item = cartItems.find((item) => item.uniqueId === uniqueId);
    if (item) {
      dispatch(addToCart({ ...item, quantity: 1 }));
    }
  };

  const handleDecreaseQuantity = (uniqueId) => {
    const item = cartItems.find((item) => item.uniqueId === uniqueId);
    if (item) {
      dispatch(decreaseFromCart({ uniqueId }));
    }
  };

  const handleRemoveFromCart = async (uniqueId) => {
    dispatch(removeFromCart({ uniqueId }));
  };

  // Update the handleCheckout function to format order details correctly
  const handleCheckout = async () => {
    try {
      const orderData = {
        fullName: 'עדן לאור',
        phoneNumber: '1234567890',
        paymentMethod: 'card',
        totalQuantity,
        totalPrice: totalPrice.toFixed(2),
        status: 'pending',
        source: 'web',
        coupon: '',
        couponType: '',
        discount: 0,
      };

      console.log(
        'Selected Values before processing:',
        cartItems[0].selectedValues
      ); // Debug log

      const orderResult = await dispatch(createNewOrder(orderData)).unwrap();

      const orderDetailsPromises = cartItems.map((item) => {
        // Group options by their parent filter
        const formattedFilterOptions = item.selectedValues.map((filter) => ({
          filterId: filter.filterId,
          filterName: filter.filterName,
          options: filter.options.map((option) => ({
            filterOptionId: option.filterOptionId,
            filterOptionName: option.filterOptionName,
            filterOptionPrice: option.filterOptionPrice,
            filterOptionSku: option.filterOptionSku,
          })),
        }));

        const orderDetailData = {
          orderId: orderResult.id,
          menuItemId: item.id,
          menuItemSku: item.sku,
          menuName: item.name,
          quantity: item.quantity,
          price: item.price,
          totalPrice: calculateItemTotalPrice(item),
          filters: formattedFilterOptions,
        };

        console.log('Order Detail Data:', orderDetailData); // Debug log
        return dispatch(createOrderDetail(orderDetailData)).unwrap();
      });

      await Promise.all(orderDetailsPromises);
      dispatch(clearCart());
      alert('ההזמנה בוצעה בהצלחה!');
      handleCloseCart();
    } catch (error) {
      console.error('Checkout failed:', error);
      alert('שגיאה בביצוע ההזמנה. אנא נסה שוב.');
    }
  };

  const calculateItemTotalPrice = (item) => {
    const basePrice = item.price;
    const selectedOptionsPrice = item.selectedValues.reduce((total, filter) => {
      return (
        total +
        filter.options.reduce((sum, option) => {
          return sum + parseFloat(option.filterOptionPrice || 0);
        }, 0)
      );
    }, 0);
    return (basePrice + selectedOptionsPrice) * item.quantity;
  };

  return (
    <>
      {totalQuantity > 0 && (
        <Button
          variant="contained"
          color="primary"
          sx={{
            position: 'sticky',
            bottom: '16px',
            marginBottom: '16px !important',
            zIndex: 1000,
            width: 'min(35rem, 95%)',
            height: '60px',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '8px 16px',
            backgroundColor: theme.palette.primary.main,
            margin: 'auto',
            overflow: 'hidden',
            maxWidth: '100%',
          }}
          onClick={handleButtonClick}
        >
          <span
            style={{
              color: theme.palette.primary.contrastText,
              fontSize: 'clamp(1rem, 4vw, 1.2rem)',
              display: 'flex',
              alignItems: 'center',
              minWidth: 0,
              flexShrink: 1,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            סך הכל {totalQuantity} פריטים - ₪{totalPrice.toFixed(2)}
          </span>
          <ShoppingCartIcon
            style={{
              color: theme.palette.primary.contrastText,
              marginLeft: '8px',
              flexShrink: 0,
              width: '24px',
              height: '24px',
            }}
          />
        </Button>
      )}
      <Dialog
        ref={scope}
        open={open}
        onClose={handleCloseCart}
        fullWidth
        maxWidth="sm"
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'transparent !important',
            boxShadow: 'none !important',
            '--Paper-shadow': 'none !important',
            '--Paper-overlay': 'none !important',
            backgroundImage: 'none !important',
          },
          '& .MuiBackdrop-root': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(0, 0, 0, 0.7)'
                : 'rgba(0, 0, 0, 0.5)',
          },
        }}
        PaperProps={{
          style: {
            position: isMobile ? 'absolute' : 'relative',
            bottom: isMobile ? 0 : 'auto',
            width: '100%',
            margin: 0,
            borderRadius: isMobile ? '16px 16px 0 0' : '16px',
            maxHeight: '85vh',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            overflow: 'visible',
          },
        }}
      >
        <motion.div
          id="cart-popup"
          ref={drawerRef}
          initial={isMobile ? { y: height || '100%' } : { opacity: 0 }}
          animate={isMobile ? { y: 0 } : { opacity: 1 }}
          transition={{
            ease: 'easeOut',
            duration: 0.3,
          }}
          style={{
            y,
            backgroundColor: theme.palette.background.paper,
            borderRadius: isMobile ? '16px 16px 0 0' : '16px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            maxHeight: '85vh',
            boxShadow:
              theme.palette.mode === 'dark'
                ? '0px 5px 15px rgba(0, 0, 0, 0.3)'
                : '0px 5px 15px rgba(0, 0, 0, 0.2)',
          }}
          drag={isMobile ? 'y' : false}
          dragControls={controls}
          dragListener={false}
          dragConstraints={{ top: 0, bottom: 0 }}
          dragElastic={0.2}
          dragMomentum={false}
          onDrag={(_, info) => {
            if (info.offset.y > 0) {
              y.set(info.offset.y);
            } else {
              y.set(0);
            }
          }}
          onDragEnd={(_, info) => {
            if (info.offset.y > 100) {
              handleCloseCart();
            } else {
              animate(
                '#cart-popup',
                {
                  y: 0,
                },
                {
                  duration: 0.2,
                  ease: 'easeOut',
                }
              );
            }
          }}
        >
          <div
            style={{
              touchAction: isMobile ? 'none' : 'auto',
              cursor: isMobile ? 'grab' : 'default',
            }}
            onPointerDown={(e) => {
              if (isMobile) {
                controls.start(e);
              }
            }}
          >
            <DialogTitle
              style={{ position: 'relative', padding: 0, marginBottom: '1rem' }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseCart}
                aria-label="close"
                style={{
                  position: 'absolute',
                  left: 26,
                  top: 16,
                  fontSize: '20px',
                  backgroundColor: theme.palette.background.default,
                  borderRadius: '50%',
                  padding: '4px',
                }}
              >
                <CloseIcon
                  sx={{ fontSize: '1.3rem', color: theme.palette.text.primary }}
                />
              </IconButton>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 'bold',
                  marginTop: '10px',
                  textAlign: 'center',
                  color: theme.palette.text.primary,
                  fontSize: isMobile ? '1.25rem' : '1.5rem',
                }}
              >
                עגלת הקניות
              </Typography>
            </DialogTitle>
          </div>
          <DialogContent
            style={{
              padding: '0px 20px', // Remove bottom padding
              paddingBottom: '92px', // Add enough space for the bottom box
              overflowY: 'auto',
              position: 'relative',
              borderRadius: '16px',
              backgroundColor:
                theme.palette.mode === 'light' ? 'rgb(240 240 240)' : '',
            }}
          >
            {cartItems.length === 0 ? (
              <Typography
                variant="body1"
                mt={2}
                textAlign="center"
                color={theme.palette.text.primary}
                sx={{ fontSize: isMobile ? '1rem' : '1.2rem' }}
              >
                עגלת הקניות ריקה
              </Typography>
            ) : (
              cartItems.map((item) => (
                <Box
                  key={item.uniqueId}
                  sx={{
                    position: 'relative',
                    marginY: '24px',
                    display: 'flex',
                    flexDirection: 'row',
                    boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.15)',
                    padding: '8px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    flex: 1,
                    backgroundColor: theme.palette.background.default,
                  }}
                  onClick={() => handleOpenMenuItemPopup(item)}
                >
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveFromCart(item.uniqueId);
                    }}
                    sx={{
                      position: 'absolute',
                      top: '-0.75rem',
                      left: '-0.75rem',
                      backgroundColor: theme.palette.secondary.main,
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0.1rem',
                      borderRadius: '50%',
                      border: 'none',
                      aspectRatio: '1 / 1',
                      minWidth: '2.5rem',
                    }}
                  >
                    <DeleteOutlineIcon
                      sx={{
                        fontSize: '1.5rem',
                        color: 'black',
                      }}
                    />
                  </Button>
                  <Box
                    sx={{
                      flex: 7,
                      textAlign: 'right',
                      alignSelf: 'baseline',
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      justifyContent: 'space-between',
                      minHeight: '6rem',
                    }}
                  >
                    <div style={{ marginLeft: '0.3rem' }}>
                      <Typography
                        variant="body"
                        color={theme.palette.text.primary}
                        sx={{ fontSize: isMobile ? '1rem' : '1.2rem' }}
                      >
                        {item.name}
                      </Typography>
                      <Box
                        sx={{
                          display: 'grid',
                          justifyContent: 'end',
                          textAlign: 'right',
                        }}
                      >
                        {item.selectedValues.map((filter, index) => (
                          <Typography
                            key={index}
                            variant="body2"
                            color={theme.palette.text.secondary}
                            sx={{
                              fontSize: isMobile ? '0.875rem' : '1rem',
                              direction: 'rtl',
                            }}
                          >
                            <strong>{filter.filterName}:</strong>{' '}
                            {filter.options
                              .map((option) => option.filterOptionName)
                              .join(', ')}
                          </Typography>
                        ))}
                      </Box>
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        textAlign: 'center',
                        alignItems: 'center',
                        marginLeft: '0.6rem',
                        width: 'clamp(3rem, 22vw, 7rem)',
                      }}
                    >
                      <div>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 'bold',
                            color: theme.palette.text.primary,
                            fontSize: isMobile ? '1rem' : '1.2rem',
                            marginBottom: '0.5rem',
                          }}
                        >
                          ₪{calculateItemTotalPrice(item).toFixed(2)}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            textAlign: 'center',
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: '0.5rem',
                            width: '100%',
                            minWidth: '6rem',
                          }}
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleIncreaseQuantity(item.uniqueId);
                            }}
                          >
                            <AddIcon
                              sx={{
                                fontSize: isMobile ? '1rem' : '1.2rem',
                                color: 'black',
                              }}
                            />
                          </IconButton>
                          <Typography
                            sx={{
                              fontSize: isMobile ? '1rem' : '1.2rem',
                              color: 'black',
                            }}
                          >
                            {item.quantity}
                          </Typography>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDecreaseQuantity(item.uniqueId);
                            }}
                          >
                            <RemoveIcon
                              sx={{
                                fontSize: isMobile ? '1rem' : '1.2rem',
                                color: 'black',
                              }}
                            />
                          </IconButton>
                        </Box>
                      </div>
                    </div>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      ml: 1,
                      flex: 2.8,
                      justifyContent: 'center',
                      maxWidth: '130px',
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: '100%', // Make image take full height of parent
                        width: '100%',
                        aspectRatio: '16 / 9',
                        objectFit: 'cover',
                        borderRadius: '0.3rem',
                        maxHeight: '100px',
                      }}
                      alt={item.name}
                      src={item.image}
                    />
                  </Box>
                </Box>
              ))
            )}
          </DialogContent>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: theme.palette.background.paper,
              padding: '16px',
              borderTop: `1px solid ${theme.palette.divider}`,
              borderRadius: '0 0 16px 16px',
              boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
              zIndex: 1,
            }}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{
                height: '60px',
                borderRadius: '12px',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                  boxShadow: '0 12px 20px rgba(0, 0, 0, 0.15)',
                  transform: 'translateY(-1px)',
                },
                '&:active': {
                  transform: 'translateY(1px)',
                },
                position: 'relative',
                overflow: 'hidden',
              }}
              disabled={cartItems.length === 0}
              onClick={handleCheckout}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {/* Left side - Price and Icon */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderRadius: '8px',
                      padding: '6px 12px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '1.3rem',
                        fontWeight: '600',
                        letterSpacing: '0.5px',
                      }}
                    >
                      ₪{totalPrice.toFixed(2)}
                    </Typography>
                    <CreditCardIcon
                      sx={{
                        fontSize: '1.6rem',
                        ml: 1,
                        color: theme.palette.primary.contrastText,
                      }}
                    />
                  </Box>
                </Box>

                {/* Center - Main Text */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: isMobile ? '1.2rem' : '1.3rem',
                      fontWeight: '600',
                      letterSpacing: '0.5px',
                    }}
                  >
                    מעבר לתשלום
                  </Typography>

                  {/* Right side - Quantity Badge */}
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.15)',
                        backdropFilter: 'blur(8px)',
                        borderRadius: '50%',
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '2px solid rgba(255, 255, 255, 0.25)',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '1.3rem',
                          fontWeight: 'bold',
                          color: theme.palette.primary.contrastText,
                        }}
                      >
                        {totalQuantity}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Button>
          </Box>
        </motion.div>
      </Dialog>
      {selectedItem && (
        <MenuItemPopup
          open={menuPopupOpen}
          onClose={handleCloseMenuItemPopup}
          item={selectedItem}
          selectedValues={selectedItem.selectedValues}
          initialQuantity={selectedItem.quantity}
          fromCart={true}
        />
      )}
    </>
  );
};

export default CartPopup;

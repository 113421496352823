import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

// Create a custom TextField with the desired styles
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& label': {
    transformOrigin: 'right !important',
    left: 'inherit !important',
    right: '0rem !important',
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: '1.1rem',
    overflow: 'unset',
  },
  '& label.Mui-focused': {
    right: '0 !important',
    color: theme.palette.primary.main,
  },
  '& .MuiInput-underline:before': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiInput-underline:hover:before': {
    borderBottom: `2px solid ${theme.palette.text.primary}`,
  },
  '& .MuiInput-underline:after': {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  '& input': {
    textAlign: 'right',
    color: theme.palette.text.primary,
  },
  '& .MuiFormHelperText-root': {
    textAlign: 'right',
    color: theme.palette.error.main,
  },
}));

const AddFilterPopup = ({ open, onClose, onAddFilter, editingFilter }) => {
  const theme = useTheme();
  const [name, setName] = useState('');
  const [options, setOptions] = useState([
    {
      name: '',
      price: '',
      id: null, // Will be null for new options
    },
  ]);
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [isMandatory, setIsMandatory] = useState(false);
  const [nameError, setNameError] = useState('');
  const [optionErrors, setOptionErrors] = useState([['', '']]);
  // Add state for tracking deleted options
  const [deletedOptions, setDeletedOptions] = useState([]);

  // Update useEffect to handle the new structure when editing
  useEffect(() => {
    if (editingFilter) {
      setName(editingFilter.name || '');
      // Convert existing options to new format
      const formattedOptions = editingFilter.options.map((option) => ({
        id: option.id || null,
        name: option.name,
        price: option.price.toString(),
      }));
      setOptions(formattedOptions);
      setIsMultiSelect(editingFilter.isMultiSelect || false);
      setIsMandatory(editingFilter.isMandatory || false);
      setOptionErrors(Array(formattedOptions.length).fill(['', '']));
    } else {
      resetForm();
    }
  }, [editingFilter]);

  const resetForm = () => {
    setName('');
    setOptions([['', '']]);
    setIsMultiSelect(false);
    setIsMandatory(false);
    setNameError('');
    setOptionErrors([['', '']]);
  };

  // Update handleAddOption
  const handleAddOption = () => {
    setOptions([
      ...options,
      {
        name: '',
        price: '',
        id: null,
      },
    ]);
    setOptionErrors([...optionErrors, ['', '']]);
  };

  // Update handleOptionChange
  const handleOptionChange = (index, field, value) => {
    const newOptions = [...options];
    const currentOption = newOptions[index];

    newOptions[index] = {
      ...currentOption,
      [field]: value,
      id: currentOption.id || null, // Let parent component handle temp IDs
      _isNew: !currentOption.id,
      _modified: currentOption.id ? true : undefined,
    };
    setOptions(newOptions);

    const newOptionErrors = [...optionErrors];
    newOptionErrors[index][field === 'name' ? 0 : 1] = '';
    setOptionErrors(newOptionErrors);
  };

  const handleRemoveOption = (index) => {
    if (options.length > 1) {
      const removedOption = options[index];

      // Only track deletion for existing options
      if (
        editingFilter?.id &&
        !editingFilter.id.startsWith('temp_') &&
        removedOption.id &&
        !removedOption.id.startsWith('temp_')
      ) {
        setDeletedOptions((prev) => [...prev, removedOption.id]);
      }

      // Remove from options array
      setOptions((prev) => prev.filter((_, i) => i !== index));
      setOptionErrors((prev) => prev.filter((_, i) => i !== index));
    }
  };

  // Update handleSubmit to include deleted options info
  // Inside AddFilterPopup.js

  const handleSubmit = () => {
    let hasError = false;

    if (!name) {
      setNameError('שדה חובה');
      hasError = true;
    } else {
      setNameError('');
    }

    const newOptionErrors = options.map((option) => {
      const errors = ['', ''];
      if (!option.name) {
        errors[0] = 'שדה חובה';
        hasError = true;
      }
      if (!option.price) {
        errors[1] = 'שדה חובה';
        hasError = true;
      }
      return errors;
    });

    setOptionErrors(newOptionErrors);

    if (!hasError) {
      const filter = {
        ...(editingFilter ? { id: editingFilter.id } : {}),
        name,
        options: options.map((opt) => ({
          id: opt.id,
          name: opt.name,
          price: opt.price,
          _modified: opt._modified, // Make sure we're preserving the _modified flag
        })),
        isMultiSelect,
        isMandatory,
        _deletedOptions: deletedOptions,
        _filterModified: editingFilter ? true : undefined, // Only set for existing filters
      };

      // For debugging
      console.log('Saving filter:', filter);
      onAddFilter(filter, editingFilter ? true : false);
      resetForm();
      onClose();
    }
  };

  // Ensure form resets when the popup is closed
  const handlePopupClose = () => {
    resetForm(); // Reset the form when closing the popup
    onClose(); // Trigger the provided onClose handler
  };

  return (
    <Dialog
      open={open}
      onClose={handlePopupClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.paper,
        },
      }}
    >
      <Box style={{ direction: 'rtl', overflowY: 'auto', padding: '1rem' }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DialogTitle
            style={{ padding: '0', color: theme.palette.text.primary }}
          >
            {editingFilter ? 'ערוך מסנן' : 'הוסף מסנן חדש'}
          </DialogTitle>
          <IconButton
            edge="end"
            onClick={onClose}
            aria-label="ביטול"
            padding="0"
          >
            <CloseIcon sx={{ color: theme.palette.text.primary }} />
          </IconButton>
        </Box>
        <DialogContent style={{ padding: '0', marginTop: '0' }}>
          <CustomTextField
            label="שם המסנן"
            fullWidth
            margin="normal"
            variant="standard"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError('');
            }}
            error={!!nameError}
            helperText={nameError}
          />
          {options.map((option, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={1}
            >
              <CustomTextField
                label="שם אופציה"
                variant="standard"
                value={option.name}
                onChange={(e) =>
                  handleOptionChange(index, 'name', e.target.value)
                }
                error={!!optionErrors[index][0]}
                helperText={optionErrors[index][0]}
              />
              <Box display="flex" alignItems="center" mr={1}>
                <CustomTextField
                  label="מחיר"
                  variant="standard"
                  type="number"
                  value={option.price}
                  onChange={(e) =>
                    handleOptionChange(index, 'price', e.target.value)
                  }
                  error={!!optionErrors[index][1]}
                  helperText={optionErrors[index][1]}
                />
                <IconButton
                  edge="end"
                  onClick={() => handleRemoveOption(index)}
                  aria-label="הסר אופציה"
                  style={{
                    marginRight: '0.5rem',
                    top: '0.7rem',
                  }}
                  disabled={options.length === 1}
                >
                  <RemoveCircleIcon sx={{ color: theme.palette.error.main }} />
                </IconButton>
              </Box>
            </Box>
          ))}
          <Button
            sx={{
              marginBottom: '1rem',
              marginTop: '1rem',
              backgroundColor:
                theme.palette.mode === 'dark' ? 'rgb(34 34 34)' : 'inherit',

              '&:hover': {
                backgroundColor:
                  theme.palette.mode === 'dark' ? 'rgb(30 30 30)' : 'inherit',
              },
            }}
            variant="outlined"
            onClick={handleAddOption}
          >
            הוסף אופציה
          </Button>
          <Box
            display={'flex'}
            justifyContent={'flex-starts'}
            flexDirection={'column'}
            width={'80%'}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isMultiSelect}
                  onChange={(e) => setIsMultiSelect(e.target.checked)}
                  sx={{
                    color: theme.palette.primary.main,
                    '&.Mui-checked': {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              }
              label={
                <Typography sx={{ color: theme.palette.text.primary }}>
                  אפשר ריבוי בחירות
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isMandatory}
                  onChange={(e) => setIsMandatory(e.target.checked)}
                  sx={{
                    color: theme.palette.primary.main,
                    '&.Mui-checked': {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              }
              label={
                <Typography sx={{ color: theme.palette.text.primary }}>
                  האם חובה
                </Typography>
              }
            />
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            style={{
              fontSize: '1.2rem',
            }}
            onClick={handleSubmit}
            color="primary"
          >
            {editingFilter ? 'עדכן מסנן' : 'שמור מסנן'}
          </Button>
          <Button
            style={{
              fontSize: '1.2rem',
            }}
            onClick={onClose}
            sx={{ color: theme.palette.text.secondary }}
          >
            ביטול
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddFilterPopup;

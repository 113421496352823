import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createFilterOption,
  updateFilterOption,
  deleteFilterOption,
  getFilterOptions,
} from '../../api/filterOptionsApi';

// Thunk to fetch all filter options
export const fetchFilterOptionsThunk = createAsyncThunk(
  'filterOptions/fetchFilterOptions', // Action type prefix
  async (_, { rejectWithValue }) => {
    try {
      const filterOptions = await getFilterOptions(); // Call the API to get all filter options
      return filterOptions; // Return the filter options data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle errors
    }
  }
);

// Thunk to add a new filter option
export const addFilterOptionThunk = createAsyncThunk(
  'filterOptions/addFilterOption', // Action type prefix
  async (filterOption, { rejectWithValue }) => {
    try {
      const newFilterOption = await createFilterOption(filterOption); // Call the API to create a new filter option
      return newFilterOption; // Return the new filter option data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle errors
    }
  }
);

// Thunk to update a filter option
export const updateFilterOptionThunk = createAsyncThunk(
  'filterOptions/updateFilterOption', // Action type prefix
  async ({ id, filterOption }, { rejectWithValue }) => {
    try {
      const updated = await updateFilterOption(id, filterOption); // Call the API to update the filter option
      return updated; // Return the updated filter option data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle errors
    }
  }
);

// Thunk to delete a filter option
export const deleteFilterOptionThunk = createAsyncThunk(
  'filterOptions/deleteFilterOption', // Action type prefix
  async (id, { rejectWithValue }) => {
    try {
      await deleteFilterOption(id); // Call the API to delete the filter option
      return id; // Return the id of the deleted filter option
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle errors
    }
  }
);

// Thunk to fetch filter options by filter ID
export const fetchFilterOptionsByFilterThunk = createAsyncThunk(
  'filterOptions/fetchFilterOptionsByFilter', // Action type prefix
  async (filterId, { rejectWithValue }) => {
    try {
      const filterOptions = await getFilterOptions(filterId); // Call the API to get filter options by filter ID
      return { filterId, filterOptions }; // Return the filter options data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle errors
    }
  }
);

export const filterOptionsThunks = {
  fetchFilterOptionsThunk,
  addFilterOptionThunk,
  updateFilterOptionThunk,
  deleteFilterOptionThunk,
  fetchFilterOptionsByFilterThunk,
};

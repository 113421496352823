import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUnifiedFile } from '../../api/Reports/unifiedFileApi';

export const fetchUnifiedFiles = createAsyncThunk(
  'unifiedFile/fetchUnifiedFiles',
  async ({ startDate, endDate, path }) => {
    const response = await getUnifiedFile({ startDate, endDate, path });
    return response;
  }
);

import { createSlice } from '@reduxjs/toolkit';
import {
  fetchWorkingHours,
  createWorkingHours,
  updateWorkingHours,
  deleteWorkingHours,
} from './workingHoursThunk';

const isStoreOpen = (workingHours) => {
  if (!workingHours || workingHours.length === 0) return false;

  const now = new Date();
  const currentDay = now.getDay(); // 0 is Sunday, 1 is Monday, etc.
  const currentTime = now.getHours() * 60 + now.getMinutes();

  const todayShifts = workingHours.filter(
    (shift) => shift.day === currentDay && shift.isOpen
  );
  if (todayShifts.length === 0) return false;

  // Convert HH:MM string to minutes since midnight
  const convertTimeToMinutes = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  };

  return todayShifts.some((shift) => {
    const openTime = convertTimeToMinutes(shift.openTime);
    const closeTime = convertTimeToMinutes(shift.closeTime);
    return currentTime >= openTime && currentTime <= closeTime;
  });
};

export const workingHoursSlice = createSlice({
  name: 'workingHours',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
    isStoreOpen: true,
  },
  reducers: {
    setStoreOpenStatus: (state) => {
      state.isStoreOpen = isStoreOpen(state.items);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkingHours.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorkingHours.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
        state.isStoreOpen = isStoreOpen(action.payload);
      })
      .addCase(fetchWorkingHours.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateWorkingHours.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateWorkingHours.fulfilled, (state, action) => {
        const updatedHours = action.payload;
        const index = state.items.findIndex((h) => h.id === updatedHours.id);
        if (index !== -1) {
          state.items[index] = updatedHours;
        }
      })
      .addCase(updateWorkingHours.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createWorkingHours.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createWorkingHours.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      .addCase(createWorkingHours.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteWorkingHours.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteWorkingHours.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.items = state.items.filter((h) => h.id !== id);
      })
      .addCase(deleteWorkingHours.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setStoreOpenStatus } = workingHoursSlice.actions;

export const selectWorkingHours = (state) => state.workingHours.items;
export const selectWorkingHoursStatus = (state) => state.workingHours.status;
export const selectIsStoreOpen = (state) => state.workingHours.isStoreOpen;

export default workingHoursSlice.reducer;

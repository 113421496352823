import { createSlice } from '@reduxjs/toolkit';
import {
  fetchCoupons,
  createCoupons,
  updateCoupons,
  deleteCoupons,
} from './couponsThunk';

const isValidCoupon = (coupon) => {
  if (!coupon.isActive) return false;
  const expiresAt = new Date(coupon.expiresAt);
  if (isNaN(expiresAt)) return false; // Handle invalid dates
  return new Date() <= expiresAt;
};

export const couponsSlice = createSlice({
  name: 'coupons',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
    validCoupons: [],
  },
  reducers: {
    updateValidCoupons: (state) => {
      state.validCoupons = state.items.filter(isValidCoupon);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoupons.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCoupons.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
        state.validCoupons = action.payload.filter(isValidCoupon);
      })
      .addCase(fetchCoupons.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateCoupons.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCoupons.fulfilled, (state, action) => {
        const updatedCoupon = action.payload;
        const index = state.items.findIndex((c) => c.id === updatedCoupon.id);
        if (index !== -1) {
          state.items[index] = updatedCoupon;
        }
        state.validCoupons = state.items.filter(isValidCoupon);
      })
      .addCase(updateCoupons.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createCoupons.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCoupons.fulfilled, (state, action) => {
        state.items.push(action.payload);
        state.validCoupons = state.items.filter(isValidCoupon);
      })
      .addCase(createCoupons.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteCoupons.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCoupons.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.items = state.items.filter((c) => c.id !== id);
        state.validCoupons = state.items.filter(isValidCoupon);
      })
      .addCase(deleteCoupons.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { updateValidCoupons } = couponsSlice.actions;

// Selectors
export const selectCoupons = (state) => state.coupons.items;
export const selectCouponsStatus = (state) => state.coupons.status;
export const selectValidCoupons = (state) => state.coupons.validCoupons;

export default couponsSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const calculateItemTotalPrice = (item) => {
  const basePrice = item.price;
  const selectedOptionsPrice = item.selectedValues.reduce((total, filter) => {
    return (
      total +
      filter.options.reduce((sum, option) => {
        return sum + parseFloat(option.filterOptionPrice || 0);
      }, 0)
    );
  }, 0);
  return (basePrice + selectedOptionsPrice) * item.quantity;
};

const saveToLocalStorage = (state) => {
  localStorage.setItem('cartItems', JSON.stringify(state.items));
};

const calculateTotals = (items) => {
  const totalQuantity = items.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = Number(
    items.reduce((total, item) => total + item.totalPrice, 0).toFixed(2)
  );
  return { totalQuantity, totalPrice };
};

const generateFilterBasedId = (item) => {
  // Create a copy of selectedValues and sort each filter's options
  const sortedSelectedValues = [...item.selectedValues]
    .map((filter) => {
      return {
        filterId: filter.filterId,
        filterName: filter.filterName,
        // Sort options by ID to ensure consistent order
        options: [...filter.options].sort((a, b) =>
          a.filterOptionId.localeCompare(b.filterOptionId)
        ),
      };
    })
    // Sort filters by filterName
    .sort((a, b) => a.filterName.localeCompare(b.filterName));

  return `${item.id}_${JSON.stringify(sortedSelectedValues)}`;
};

const initialState = {
  items: JSON.parse(localStorage.getItem('cartItems')) || [],
  totalQuantity: 0,
  totalPrice: 0,
};

if (initialState.items.length > 0) {
  const totals = calculateTotals(initialState.items);
  initialState.totalQuantity = totals.totalQuantity;
  initialState.totalPrice = totals.totalPrice;
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
      const filterBasedId = generateFilterBasedId(newItem);
      const existingItem = state.items.find(
        (item) => item.filterBasedId === filterBasedId
      );

      if (existingItem) {
        existingItem.quantity += newItem.quantity;
        existingItem.totalPrice = calculateItemTotalPrice(existingItem);
      } else {
        newItem.uniqueId = uuidv4();
        newItem.filterBasedId = filterBasedId;
        newItem.totalPrice = calculateItemTotalPrice(newItem);
        state.items.push(newItem);
      }

      const totals = calculateTotals(state.items);
      state.totalQuantity = totals.totalQuantity;
      state.totalPrice = totals.totalPrice;

      saveToLocalStorage(state);
      console.log('Cart after adding item:', state.items);
    },

    updateCartItem: (state, action) => {
      const updatedItem = { ...action.payload };
      const filterBasedId = generateFilterBasedId(updatedItem);
      const existingItemIndex = state.items.findIndex(
        (item) => item.uniqueId === updatedItem.uniqueId
      );

      if (existingItemIndex !== -1) {
        // Find if there's another item with the same filters
        const itemWithSameFilters = state.items.find(
          (item) =>
            item.filterBasedId === filterBasedId &&
            item.uniqueId !== updatedItem.uniqueId
        );

        if (itemWithSameFilters) {
          // Merge quantities if same filters found
          itemWithSameFilters.quantity += updatedItem.quantity;
          itemWithSameFilters.totalPrice =
            calculateItemTotalPrice(itemWithSameFilters);
          // Remove the original item
          state.items.splice(existingItemIndex, 1);
        } else {
          // Update existing item
          updatedItem.filterBasedId = filterBasedId;
          updatedItem.totalPrice = calculateItemTotalPrice(updatedItem);
          state.items[existingItemIndex] = updatedItem;
        }
      } else {
        // Add as new item if no existing item found
        updatedItem.uniqueId = updatedItem.uniqueId || uuidv4();
        updatedItem.filterBasedId = filterBasedId;
        updatedItem.totalPrice = calculateItemTotalPrice(updatedItem);
        state.items.push(updatedItem);
      }

      const totals = calculateTotals(state.items);
      state.totalQuantity = totals.totalQuantity;
      state.totalPrice = totals.totalPrice;

      saveToLocalStorage(state);
      console.log('Cart after updating item:', state.items);
    },

    removeFromCart: (state, action) => {
      const uniqueId = action.payload.uniqueId;
      const existingItem = state.items.find(
        (item) => item.uniqueId === uniqueId
      );

      if (existingItem) {
        state.items = state.items.filter((item) => item.uniqueId !== uniqueId);
        const totals = calculateTotals(state.items);
        state.totalQuantity = totals.totalQuantity;
        state.totalPrice = totals.totalPrice;
      }
      saveToLocalStorage(state);
      console.log('Cart after removing item:', state.items);
    },

    decreaseFromCart: (state, action) => {
      const uniqueId = action.payload.uniqueId;
      const existingItem = state.items.find(
        (item) => item.uniqueId === uniqueId
      );

      if (existingItem && existingItem.quantity > 1) {
        existingItem.quantity -= 1;
        existingItem.totalPrice = calculateItemTotalPrice(existingItem);
        const totals = calculateTotals(state.items);
        state.totalQuantity = totals.totalQuantity;
        state.totalPrice = totals.totalPrice;
      }
      saveToLocalStorage(state);
      console.log('Cart after decreasing item quantity:', state.items);
    },

    clearCart: (state) => {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
      localStorage.removeItem('cartItems');
      console.log('Cart after clearing:', state.items);
    },
  },
});

export const {
  addToCart,
  updateCartItem,
  removeFromCart,
  decreaseFromCart,
  clearCart,
} = cartSlice.actions;

export const selectTotalQuantity = (state) => state.cart.totalQuantity;
export const selectTotalPrice = (state) => state.cart.totalPrice;
export const selectCartItems = (state) => state.cart.items;

export default cartSlice.reducer;

import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectIsStoreOpen } from '../../../features/workingHours/workingHoursSlice';
import { selectConfiguration } from '../../../features/configuration/configurationSlice';

function ImageTitleHeader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isStoreOpen = useSelector(selectIsStoreOpen);
  const configurations = useSelector(selectConfiguration);

  // Get the first configuration object since it contains all our settings
  const config = configurations[0] || {};
  const { placeName, mainImage, address } = config;

  const scrollToFooter = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const linkStyle = {
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationThickness: '1px',
    textUnderlineOffset: '3px',
    '&:hover': {
      opacity: 0.8,
    },
  };

  return (
    <Box
      height="30vw"
      maxHeight={'300px'}
      sx={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        direction: 'rtl',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${mainImage || ''})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(50%)',
        }}
      />
      <Typography
        component="h1"
        sx={{
          fontSize: isMobile ? '2rem' : '3rem',
          fontWidth: '500',
          position: 'absolute',
          top: 'calc(20% + 3vw)',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#fff',
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          padding: '1rem',
          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
          borderRadius: '8px',
        }}
      >
        {placeName || 'Food Truck'}
      </Typography>
      <Box
        sx={{
          fontSize: isMobile ? '1rem' : '2rem',
          fontWidth: '500',
          position: 'absolute',
          top: 'calc(65% + -1vw)',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#fff',
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          padding: '1rem',
          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          component="h4"
          onClick={scrollToFooter}
          sx={{
            fontSize: isMobile ? '1rem' : '2rem',
            ...linkStyle,
          }}
        >
          {'שעות הפעילות'}
        </Typography>
        <Divider
          sx={{
            width: '0px',
            borderColor: '#fff',
            borderWidth: '1px',
            margin: '0 1rem',
            maxHeight: '30px',
            height: '3vw',
          }}
          orientation="horizontal"
        />
        <Typography
          component="h4"
          onClick={scrollToFooter}
          sx={{
            fontSize: isMobile ? '1rem' : '2rem',
            ...linkStyle,
          }}
        >
          {address || 'רחוב הרשאי 123, תל אביב'}
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: '0',
          textAlign: 'center',
          width: '100%',
          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgb(200 0 0 / 60%)',
        }}
      >
        {!isStoreOpen && (
          <Typography
            component="h4"
            sx={{
              fontSize: isMobile ? '1rem' : '1.6rem',
              color: 'white',
            }}
          >
            {'החנות סגורה כעת'}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default ImageTitleHeader;

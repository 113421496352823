import React, { useState, useEffect } from 'react';
import { Popover, Typography, Button, CircularProgress } from '@mui/material';
import { DragIndicator, Add as AddIcon, Edit } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import './CategoryPopup.css';

// Custom drop animation for consistency
const dropAnimationConfig = {
  keyframes: ({ transform }) => [
    { transform: CSS.Transform.toString(transform.initial) },
    { transform: CSS.Transform.toString(transform.final) },
  ],
  easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
  duration: 200,
};

const DraggableCategory = ({
  category,
  onCategoryNameChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [categoryName, setCategoryName] = useState(category.name);
  const theme = useTheme();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ 
    id: category.id,
    disabled: isEditing,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  };

  useEffect(() => {
    setCategoryName(category.name);
  }, [category.name]);

  const handleEdit = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onCategoryNameChange(category.id, categoryName);
  };

  return (
    <div
      ref={setNodeRef}
      className={`draggable-category ${isDragging ? 'dragging' : ''}`}
      style={style}
    >
      {isEditing ? (
        <input
          type="text"
          value={categoryName}
          onChange={handleNameChange}
          onBlur={handleBlur}
          autoFocus
          className="editable-input"
          style={{ borderColor: theme.palette.primary.main }}
          onClick={e => e.stopPropagation()}
        />
      ) : (
        <span className="category-text">
          {categoryName}
          <Edit
            className="edit-icon"
            onClick={handleEdit}
            style={{
              fontSize: '0.9rem',
              marginRight: '0.4rem',
              color: theme.palette.primary.main,
            }}
          />
        </span>
      )}
      <div {...attributes} {...listeners} className="drag-icon">
        <DragIndicator style={{ color: theme.palette.text.secondary }} />
      </div>
    </div>
  );
};

const CategoryPopup = ({
  categories,
  onCategoriesUpdate,
  onSaveCategories,
  onCancelChanges,
  isLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [localCategories, setLocalCategories] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const theme = useTheme();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 60,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (Array.isArray(categories) && categories.length > 0) {
      const sortedCategories = categories.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
      setLocalCategories([...sortedCategories]);
    }
  }, [categories]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = localCategories.findIndex(cat => cat.id === active.id);
      const newIndex = localCategories.findIndex(cat => cat.id === over.id);

      const updatedCategories = arrayMove(localCategories, oldIndex, newIndex);
      
      updatedCategories.forEach((category, index) => {
        category.displayOrder = index + 1;
      });

      setLocalCategories(updatedCategories);
      onCategoriesUpdate(updatedCategories);
      setHasChanges(true);
    }
    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const handleCategoryNameChange = (categoryId, newName) => {
    const updatedCategories = localCategories.map((category) =>
      category.id === categoryId ? { ...category, name: newName } : category
    );
    setLocalCategories(updatedCategories);
    onCategoriesUpdate(updatedCategories);
    setHasChanges(true);
  };

  const handleSave = () => {
    onSaveCategories(localCategories);
    handleClose();
  };

  const handleCancel = () => {
    if (hasChanges) {
      onCancelChanges();
    }
    handleClose();
  };

  const handleAddCategory = () => {
    const newCategory = {
      id: `temp_${Date.now()}`,
      name: 'קטגוריה חדשה',
      displayOrder: localCategories.length + 1,
    };
    const updatedCategories = [...localCategories, newCategory];
    setLocalCategories(updatedCategories);
    onCategoriesUpdate(updatedCategories);
    setHasChanges(true);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        startIcon={
          <Edit
            style={{
              fontSize: '1rem',
            }}
          />
        }
        className="reorder-button"
        style={{
          backgroundColor: theme.palette.primary.main,
          minWidth: '200px',
          borderRadius: '0.5rem',
          height: '3rem',
        }}
      >
        עריכת/הוספת קטגוריות
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            className: 'category-popover-paper',
            style: {
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            },
          },
        }}
      >
        <Typography
          variant="h6"
          className="popover-title"
          style={{ marginBottom: '0', color: theme.palette.text.primary }}
        >
          שינוי סדר קטגוריות
        </Typography>
        <Typography
          variant="body2"
          style={{
            marginBottom: '0.5rem',
            textAlign: 'right',
            color: theme.palette.text.secondary,
          }}
        >
          לשינוי סדר תצוגת הקטגוריות יש לגרור את הקטגוריה למקום הרצוי
        </Typography>
        <div 
          className="popover-content"
          style={{ overscrollBehavior: 'contain' }}
        >
          {isLoading ? (
            <div className="loading-container">
              <CircularProgress />
            </div>
          ) : (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragCancel={handleDragCancel}
            >
              <SortableContext 
                items={localCategories.map(cat => cat.id)} 
                strategy={verticalListSortingStrategy}
              >
                {localCategories.map((category) => (
                  <DraggableCategory
                    key={category.id}
                    category={category}
                    onCategoryNameChange={handleCategoryNameChange}
                  />
                ))}
              </SortableContext>

              <DragOverlay dropAnimation={dropAnimationConfig}>
                {activeId ? (
                  <div 
                    className="draggable-category dragging"
                    style={{
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                      width: 'calc(100% - 32px)',
                    }}
                  >
                    <span className="category-text">
                      {localCategories.find(cat => cat.id === activeId)?.name}
                    </span>
                    <div className="drag-icon">
                      <DragIndicator style={{ color: theme.palette.text.secondary }} />
                    </div>
                  </div>
                ) : null}
              </DragOverlay>
            </DndContext>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={handleAddCategory}
            className="add-category-button"
            style={{
              backgroundColor: theme.palette.background.paper,
              fontSize: '0.875rem',
              border: `1px solid ${theme.palette.primary.main}`,
              color: theme.palette.primary.main,
              width: '100%',
              boxShadow: 'none',
            }}
            startIcon={<AddIcon />}
          >
            הוסף קטגוריה חדשה
          </Button>
        </div>
        <div className="button-container">
          <Button
            onClick={handleCancel}
            variant="outlined"
            className="cancel-button"
            style={{
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
            }}
          >
            ביטול
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            className="save-button"
            disabled={isLoading}
          >
            שמירה
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default CategoryPopup;
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Snackbar,
  Alert,
  Paper,
  TextField,
  MenuItem,
  Stack,
  LinearProgress,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Search as SearchIcon,
  FileCopy as FileCopyIcon,
  Share as ShareIcon,
  QrCode as QrCodeIcon,
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import { Add as AddIcon } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { QRCodeCanvas as QRCode } from 'qrcode.react';
import DeletionManager from '../components/dashboardComponents/StoreSettings/EmployeeFormComponents/DeletionManager';
import {
  selectForms,
  selectFormsLoading,
  selectFormsError,
} from '../features/EmployeeForms/formsSlice';
import { loadFormsData, deleteForm } from '../features/EmployeeForms/formThunk';

const DialogWrapper = React.forwardRef((props, ref) => {
  return <Paper ref={ref} {...props} />;
});

const MotionGrid = motion(Grid);
const MotionCard = motion(Card);

const EmployeeForms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux state
  const forms = useSelector(selectForms);
  const isLoading = useSelector(selectFormsLoading);
  const error = useSelector(selectFormsError);

  const [filteredForms, setFilteredForms] = React.useState([]);
  const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [sortBy, setSortBy] = React.useState('date');
  const [sortOrder, setSortOrder] = React.useState('desc');
  const [isQRDialogOpen, setQRDialogOpen] = React.useState(false);
  const [selectedFormUrl, setSelectedFormUrl] = React.useState('');

  const showSnackbar = useCallback((message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  }, []);

  useEffect(() => {
    dispatch(loadFormsData());
  }, [dispatch]);

  useEffect(() => {
    let result = [...forms];
    if (searchQuery) {
      result = result.filter(
        (form) =>
          form.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          form.fields.some((field) =>
            field.label.toLowerCase().includes(searchQuery.toLowerCase())
          )
      );
    }
    result.sort((a, b) => {
      let comparison = 0;
      switch (sortBy) {
        case 'title':
          comparison = a.title.localeCompare(b.title);
          break;
        case 'questions':
          comparison = a.fields.length - b.fields.length;
          break;
        case 'date':
        default:
          comparison = new Date(b.createdAt) - new Date(a.createdAt);
      }
      return sortOrder === 'asc' ? comparison : -comparison;
    });
    setFilteredForms(result);
  }, [forms, searchQuery, sortBy, sortOrder]);

  const handleFillForm = (form) => {
    navigate(`/forms/fill/${form.id}`);
  };

  const handleDeleteForm = async (form) => {
    try {
      await dispatch(deleteForm({ formId: form.id })).unwrap();
      showSnackbar('הטופס נמחק בהצלחה');
      return true;
    } catch (error) {
      showSnackbar('שגיאה במחיקת הטופס', 'error');
      throw new Error('Failed to delete form');
    }
  };

  const handleDeleteLinkedSubmissions = async (form) => {
    try {
      await dispatch(
        deleteForm({ formId: form.id, deleteSubmissions: true })
      ).unwrap();
      showSnackbar('הטופס וההגשות המקושרות נמחקו בהצלחה');
      return true;
    } catch (error) {
      showSnackbar('שגיאה במחיקת ההגשות המקושרות', 'error');
      throw new Error('Failed to delete linked submissions');
    }
  };

  const handleQRCode = (form) => {
    const formUrl = `${window.location.origin}/forms/fill/${form.id}`;
    setSelectedFormUrl(formUrl);
    setQRDialogOpen(true);
  };

  const handleCopyLink = useCallback(
    (form) => {
      const formUrl = `${window.location.origin}/forms/fill/${form.id}`;
      navigator.clipboard
        .writeText(formUrl)
        .then(() => {
          showSnackbar('הקישור הועתק ללוח');
        })
        .catch(() => {
          showSnackbar('שגיאה בהעתקת הקישור', 'error');
        });
    },
    [showSnackbar]
  );

  const handleDownload = useCallback(
    (form) => {
      try {
        const blob = new Blob([JSON.stringify(form, null, 2)], {
          type: 'application/json',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `form_${form.title.replace(/\s+/g, '_')}_${form.id}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        showSnackbar('הטופס הורד בהצלחה');
      } catch (error) {
        showSnackbar('שגיאה בהורדת הטופס', 'error');
      }
    },
    [showSnackbar]
  );

  const renderQRDialog = () => (
    <Dialog
      open={isQRDialogOpen}
      onClose={() => setQRDialogOpen(false)}
      PaperComponent={DialogWrapper}
    >
      <DialogTitle>
        <Typography variant="h6">קוד QR לטופס</Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
          }}
        >
          <QRCode value={selectedFormUrl} size={256} level="H" />
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mt: 2, mb: 1 }}
          >
            סרוק את קוד ה-QR כדי לגשת לטופס
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <TextField
              value={selectedFormUrl}
              variant="outlined"
              size="small"
              fullWidth
              slotProps={{readOnly: true}}
            />
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(selectedFormUrl);
                showSnackbar('הקישור הועתק ללוח');
              }}
              size="small"
              sx={{ ml: 1 }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setQRDialogOpen(false)}>סגור</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box sx={{ p: 3, textAlign: 'right' }}>
      {isLoading && <LinearProgress sx={{ mb: 2 }} />}

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 3 }}>
        <TextField
          label="חיפוש טפסים"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
         // InputProps={{startAdornment: (<SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />),}}
          slotProps={{startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />}}
          sx={{ flexGrow: 1 }}
        />
        <TextField
          select
          label="מיין לפי"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          size="small"
          sx={{ minWidth: 120 }}
        >
          <MenuItem value="date">תאריך יצירה</MenuItem>
          <MenuItem value="title">כותרת</MenuItem>
          <MenuItem value="questions">מספר שאלות</MenuItem>
        </TextField>
        <TextField
          select
          label="סדר"
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
          size="small"
          sx={{ minWidth: 100 }}
        >
          <MenuItem value="desc">יורד</MenuItem>
          <MenuItem value="asc">עולה</MenuItem>
        </TextField>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/CreateEmployeeForm')}
          startIcon={<AddIcon />}
        >
          יצירת טופס
        </Button>
      </Stack>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {filteredForms.length === 0 ? (
        <Typography
          variant="body1"
          color="text.secondary"
          textAlign="center"
          mt={4}
        >
          {searchQuery
            ? 'לא נמצאו טפסים התואמים את החיפוש'
            : 'לא נוצרו טפסים עדיין'}
        </Typography>
      ) : (
        <AnimatePresence>
          <Grid
            container
            spacing={3}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'right',
            }}
          >
            {filteredForms.map((form) => (
              <MotionGrid
                item
                xs={12}
                sm={6}
                md={4}
                key={form.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
              >
                <MotionCard
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <CardContent>
                    <Typography variant="h6" component="div" gutterBottom>
                      {form.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="div"
                    >
                      נוצר: {new Date(form.createdAt).toLocaleDateString()}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="div"
                    >
                      שאלות: {form.fields.length}
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{
                      flexWrap: 'wrap',
                      gap: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <IconButton
                        onClick={() => handleFillForm(form)}
                        title="מלא טופס"
                        size="small"
                        color="primary"
                      >
                        <FileCopyIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleQRCode(form)}
                        title="קוד QR"
                        size="small"
                      >
                        <QrCodeIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleCopyLink(form)}
                        title="העתק קישור"
                        size="small"
                      >
                        <ShareIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDownload(form)}
                        title="הורד"
                        size="small"
                      >
                        <DownloadIcon />
                      </IconButton>
                      <DeletionManager
                        item={form}
                        title={`מחיקת טופס "${form.title}"`}
                        description="האם אתה בטוח שברצונך למחוק טופס זה? פעולה זו אינה הפיכה."
                        onDelete={handleDeleteForm}
                        onDeleteLinked={handleDeleteLinkedSubmissions}
                        linkedItemsText="מחק גם את כל ההגשות המקושרות לטופס זה"
                        deletingText="מוחק טופס..."
                      >
                        <IconButton color="error" size="small" title="מחק טופס">
                          <DeleteIcon />
                        </IconButton>
                      </DeletionManager>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        onClick={() => navigate('/forms/submissions')}
                        startIcon={<AssignmentTurnedInIcon />}
                      >
                        צפה בהגשות
                      </Button>
                    </Box>
                  </CardActions>
                </MotionCard>
              </MotionGrid>
            ))}
          </Grid>
        </AnimatePresence>
      )}

      {renderQRDialog()}

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmployeeForms;

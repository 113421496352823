import React from 'react';
import { DragIndicator } from '@mui/icons-material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const DraggableItem = ({
  id,
  children,
  dragIconStyle,
  className = '',
  style = {},
  renderIcon = true,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const dragStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    ...style,
  };

  // If we're using render function for children, pass the sortable props
  if (typeof children === 'function') {
    return (
      <div
        ref={setNodeRef}
        className={`draggable-item ${isDragging ? 'dragging' : ''} ${className}`}
        style={dragStyle}
      >
        {children(attributes, listeners)}
      </div>
    );
  }

  // Default rendering with icon
  return (
    <div
      ref={setNodeRef}
      className={`draggable-item ${isDragging ? 'dragging' : ''} ${className}`}
      style={dragStyle}
    >
      {children}
      {renderIcon && (
        <div {...attributes} {...listeners} className="drag-icon">
          <DragIndicator style={dragIconStyle} />
        </div>
      )}
    </div>
  );
};

export default DraggableItem;
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectConfiguration,
  updateConfigurations,
} from '../../../features/configuration/configurationSlice';
import {
  Box,
  TextField,
  Button,
  Stack,
  Typography,
  Container,
  Switch,
  FormControlLabel,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

// Define the fields we want to always show
const CONFIG_FIELDS = [
  { key: 'placeName', label: 'שם העסק', type: 'text', required: true },
  { key: 'address', label: 'כתובת העסק', type: 'text', required: true },
  { key: 'phone', label: 'טלפון', type: 'text', required: true },
  { key: 'email', label: 'אימייל', type: 'text' },
  { key: 'facebook', label: 'קישור לעמוד פייסבוק', type: 'text' },
  { key: 'instagram', label: 'קישור לעמוד אינסטגרם', type: 'text' },
  { key: 'isOpen', label: 'המקום פתוח', type: 'boolean' },
  { key: 'manageStock', label: 'ניהול מלאי', type: 'boolean' },
];

const SaveButton = ({ onClick, disabled }) => (
  <Button
    variant="contained"
    endIcon={<SaveIcon />}
    onClick={onClick}
    disabled={disabled}
    sx={{
      minWidth: 120,
      '& .MuiButton-endIcon': {
        marginRight: 1,
        marginLeft: -1,
      },
    }}
  >
    שמור שינויים
  </Button>
);

const ConfigurationManager = () => {
  const dispatch = useDispatch();
  const configuration = useSelector(selectConfiguration);
  const [editedValues, setEditedValues] = useState({});
  const [validationErrors, setValidationErrors] = useState({});

  // Validate Israeli phone number
  const isValidIsraeliPhoneNumber = (phone) => {
    // Regex for Israeli mobile and landline numbers
    const phoneRegex = /^(0(5[0-9]|[2-4]|[8-9])|\\+9725[0-9])[- ]?(\d{7})$/;
    return phoneRegex.test(phone.replace(/\s/g, ''));
  };

  const handleEditChange = (id, field, value) => {
    setEditedValues((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: typeof value === 'boolean' ? value : value,
      },
    }));

    // Clear any existing validation error for this field
    if (validationErrors[id]) {
      const updatedErrors = { ...validationErrors };
      delete updatedErrors[id];
      setValidationErrors(updatedErrors);
    }
  };

  const handleSaveAll = async () => {
    // Reset validation errors
    setValidationErrors({});
    const newValidationErrors = {};

    configuration.forEach((config) => {
      const configErrors = {};

      // Check required text fields
      CONFIG_FIELDS.filter(
        (field) => field.required && field.type === 'text'
      ).forEach((field) => {
        const value = editedValues[config.id]?.[field.key] ?? config[field.key];

        // Check if value is empty
        if (!value || value.trim() === '') {
          configErrors[field.key] = 'שדה חובה';
        }

        // Validate phone number format if it's a phone field
        if (
          field.key === 'phone' &&
          value &&
          !isValidIsraeliPhoneNumber(value)
        ) {
          configErrors[field.key] = 'מספר טלפון לא תקין';
        }
      });

      // If there are errors for this config, add to validation errors
      if (Object.keys(configErrors).length > 0) {
        newValidationErrors[config.id] = configErrors;
      }
    });

    // If there are validation errors, set them and prevent saving
    if (Object.keys(newValidationErrors).length > 0) {
      setValidationErrors(newValidationErrors);
      return;
    }

    try {
      const updatePromises = configuration.map(async (config) => {
        if (editedValues[config.id]) {
          const updatedConfig = {
            ...config,
            ...editedValues[config.id],
          };

          await dispatch(
            updateConfigurations({
              id: config.id,
              configuration: updatedConfig,
            })
          ).unwrap();

          return config;
        }
        return config;
      });

      await Promise.all(updatePromises);
      setEditedValues({});
    } catch (error) {
      console.error('Failed to save configurations:', error);
    }
  };

  const renderField = (config, field) => {
    const { key, label, type, required } = field;

    // Get the current value, prioritizing edited values, then config, then default
    const currentValue =
      editedValues[config.id]?.[key] ??
      config[key] ??
      (type === 'boolean' ? false : '');

    // Check for validation error
    const hasError = validationErrors[config.id]?.[key];

    if (type === 'boolean') {
      return (
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{
              mb: 1,
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: 'text.primary',
            }}
          >
            {label}
            {required && (
              <span style={{ color: 'red', marginRight: '5px' }}>*</span>
            )}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              py: 1,
              bgcolor: 'background.default',
              borderRadius: 1,
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(currentValue)}
                  onChange={(e) =>
                    handleEditChange(config.id, key, e.target.checked)
                  }
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: 'primary.main',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: 'primary.main',
                    },
                  }}
                />
              }
              label={currentValue ? 'פעיל' : 'לא פעיל'}
              sx={{
                margin: 0,
                '& .MuiFormControlLabel-label': {
                  color: 'text.secondary',
                },
              }}
            />
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="subtitle1"
          sx={{
            mb: 1,
            fontWeight: 'bold',
            fontSize: '1.1rem',
            color: 'text.primary',
          }}
        >
          {label}
          {required && (
            <span style={{ color: 'red', marginRight: '5px' }}>*</span>
          )}
        </Typography>
        <TextField
          fullWidth
          value={currentValue || ''}
          onChange={(e) => handleEditChange(config.id, key, e.target.value)}
          variant="outlined"
          error={!!hasError}
          FormHelperTextProps={{
            sx: {
              textAlign: 'right',
              fontSize: '1rem',
              marginRight: 0,
            },
          }}
          helperText={hasError}
          sx={{
            '& .MuiOutlinedInput-root': {
              direction: 'rtl',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              textAlign: 'right',
            },
          }}
          InputProps={{
            sx: {
              direction: 'rtl',
              textAlign: 'right',
            },
          }}
        />
      </Box>
    );
  };

  const hasChanges = Object.keys(editedValues).length > 0;

  return (
    <Container maxWidth="md" dir="rtl" sx={{ padding: '0' }}>
      <Box elevation={3} sx={{ mt: 4, mb: 4 }}>
        <Stack sx={{ direction: 'rtl' }}>
          {configuration.map((config) => (
            <Box key={config.id}>
              {CONFIG_FIELDS.map((field) => (
                <Box key={`${config.id}-${field.key}`}>
                  {renderField(config, field)}
                </Box>
              ))}
            </Box>
          ))}
        </Stack>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            mt: 4,
            direction: 'rtl',
          }}
        >
          <SaveButton onClick={handleSaveAll} disabled={!hasChanges} />
        </Box>
      </Box>
    </Container>
  );
};

export default ConfigurationManager;

// unifiedFileSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchUnifiedFiles } from './unifiedFileThunk';

export const unifiedFileSlice = createSlice({
  name: 'unifiedFile',
  initialState: {
    files: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnifiedFiles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUnifiedFiles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.files = Array.isArray(action.payload) ? action.payload : [];
        state.error = null;
      })
      .addCase(fetchUnifiedFiles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Selectors
export const selectUnifiedFiles = (state) => state.unifiedFile.files;
export const selectUnifiedFileStatus = (state) => state.unifiedFile.status;
export const selectUnifiedFileError = (state) => state.unifiedFile.error;

export default unifiedFileSlice.reducer;

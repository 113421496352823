import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Box,
  Divider,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid2';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ImageCropper from '../utils/ImageCropper';
import CategoryDropdown from '../Category/CategoryDropdown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditableField from '../../core/editableField';
import AddIcon from '@mui/icons-material/Add';
import { DragIndicator } from '@mui/icons-material';
import AddFilterPopup from '../Filters/addFilterPopup';
import FilterOrderPopup from '../Filters/FilterOrderPopup';
import EditIcon from '@mui/icons-material/Edit';
import ImagePlaceholder from '../../../assets/ImagePlaceholder.png';
import { useSelector } from 'react-redux';
import { selectConfiguration } from '../../../features/configuration/configurationSlice';

/** A popup for editing a single menu item in the dashboard */
const MenuItemPopupDashItem = ({ open, onClose, item, onSave, categories }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [editedItem, setEditedItem] = useState({
    id: '',
    name: '',
    description: '',
    image: null,
    filters: [],
    ...item,
    price: Number(item.price) || 0,
    category: item.category ? item.category.id : '',
    isActive: item.isActive !== undefined ? item.isActive : true,
    quantityInStock: item.quantityInStock || 0,
    isAvailable: item.isAvailable !== undefined ? item.isAvailable : true,
  });
  const [isCropperOpen, setIsCropperOpen] = useState(false); // State to manage cropper visibility
  const [croppedImage, setCroppedImage] = useState(null); // State to store cropped image
  const [imageFile, setImageFile] = useState(null); // State to store the image file
  const fileInputRef = useRef(null);
  const [modifiedFilters, setModifiedFilters] = useState({});
  const [isAddFilterDialogOpen, setIsAddFilterDialogOpen] = useState(false);
  const [isFilterOrderPopupOpen, setIsFilterOrderPopupOpen] = useState(false); // New state for FilterOrderPopup
  const [errors, setErrors] = useState({});
  const [editingFilter, setEditingFilter] = useState(null);
  const configurations = useSelector(selectConfiguration);
  const [isSaving, setIsSaving] = useState(false);
  const manageStock = configurations?.[0]?.manageStock || false;

  useEffect(() => {
    if (item) {
      // Ensure filters and their options have all required properties
      const processedFilters =
        item.filters?.map((filter) => ({
          ...filter,
          options:
            filter.options?.map((option) => ({
              ...option,
              price: option.price?.toString() || '0', // Ensure price is string for form handling
            })) || [],
        })) || [];

      setEditedItem({
        id: item.id || '',
        name: item.name || '',
        description: item.description || '',
        image: item.image || null,
        filters: processedFilters,
        price: Number(item.price) || 0,
        category: item?.category?.id || '',
        isActive: item.isActive !== undefined ? item.isActive : true,
        quantityInStock: item.quantityInStock || 0,
        isAvailable: item.isAvailable !== undefined ? item.isAvailable : true,
      });

      // Reset modified filters when item changes
      setModifiedFilters({});
    }
  }, [item]); // Only depend on item changes

  const validateForm = () => {
    const newErrors = {};

    if (!editedItem.name.trim()) {
      newErrors.name = 'שדה חובה';
    }
    if (!editedItem.price || editedItem.price <= 0) {
      newErrors.price = 'נדרש מחיר חוקי';
    }
    if (!editedItem.category) {
      newErrors.category = 'יש לבחור קטגוריה';
    }

    // Update this section to handle the new options structure
    editedItem.filters.forEach((filter) => {
      if (!filter.name.trim()) {
        newErrors[`filter_${filter.id}_name`] = 'שדה חובה';
      }

      // Check if options exist and is an array
      if (filter.options && Array.isArray(filter.options)) {
        filter.options.forEach((option, optionIndex) => {
          if (!option.name?.trim()) {
            if (!newErrors[filter.id]) {
              newErrors[filter.id] = [];
            }
            if (!newErrors[filter.id][optionIndex]) {
              newErrors[filter.id][optionIndex] = {};
            }
            newErrors[filter.id][optionIndex].name = 'שדה חובה';
          }
          if (!option.price) {
            if (!newErrors[filter.id]) {
              newErrors[filter.id] = [];
            }
            if (!newErrors[filter.id][optionIndex]) {
              newErrors[filter.id][optionIndex] = {};
            }
            newErrors[filter.id][optionIndex].price = 'שדה חובה';
          }
        });
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /* useEffect(() => {
    console.log('Errors state updated:', errors);
  }, [errors]); */
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Define allowed file types and maximum file size (e.g., 10MB)
      const allowedTypes = ['image/jpeg', 'image/png'];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes

      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {
        alert('Only JPEG, PNG are allowed.');
        return;
      }

      // Check if the file size is within the limit
      if (file.size > maxSize) {
        alert('File size must be less than 10MB.');
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setCroppedImage(reader.result);
        setIsCropperOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCrop = (croppedBlob) => {
    const url = URL.createObjectURL(croppedBlob);
    setCroppedImage(url); // Set the URL of the cropped image for display
    setImageFile(croppedBlob); // Store the cropped Blob
    setEditedItem({ ...editedItem, image: url }); // Update the edited item with the image URL
    setIsCropperOpen(false);
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSwitchChange = (field) => (event) => {
    setEditedItem((prev) => ({
      ...prev,
      [field]: event.target.checked,
    }));
  };

  const handleInputChange = (field, value) => {
    const updatedItem = {
      ...editedItem,
      [field]: field === 'price' ? Number(value) : value,
    };
    if (field === 'category') {
      updatedItem.category = value;
    }
    setEditedItem(updatedItem);

    // Only clear error if the new value is valid
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (field === 'name' && updatedItem.name.trim()) {
        newErrors.name = '';
      } else if (
        field === 'price' &&
        (updatedItem.price || updatedItem.price >= 0)
      ) {
        newErrors.price = '';
      } else if (field === 'category' && updatedItem.category) {
        delete newErrors[field];
      }
      return newErrors;
    });
  };

  const handleUpdateFiltersOrder = (updatedFilters) => {
    // Ensure each updated filter has an ID and log any missing IDs
    const processedUpdatedFilters = updatedFilters.map((filter) => {
      if (!filter.id) {
        const tempId = `temp_${Date.now()}_${Math.random()}`;
        console.warn(
          `Filter missing id. Assigning temporary id: ${tempId}`,
          filter
        );
        return { ...filter, id: tempId };
      }
      return filter;
    });

    // Update both states atomically
    setEditedItem((prevItem) => {
      const updatedFiltersState = prevItem.filters.map((filter) => {
        const updatedFilter = processedUpdatedFilters.find(
          (uf) => uf.id === filter.id
        );
        return updatedFilter ? { ...filter, ...updatedFilter } : filter;
      });

      return {
        ...prevItem,
        filters: updatedFiltersState,
      };
    });

    // Track modifications for each updated filter
    const modifiedFilterUpdates = processedUpdatedFilters.reduce(
      (acc, filter) => {
        acc[filter.id] = {
          ...filter,
          _filterModified: true,
        };
        return acc;
      },
      {}
    );

    setModifiedFilters((prev) => ({
      ...prev,
      ...modifiedFilterUpdates,
    }));
  };

  const handleEditFilter = (filter) => {
    setEditingFilter(filter);
    setIsAddFilterDialogOpen(true);
  };

  const handleAddFilter = (newFilter) => {
    const tempId = `temp_${Date.now()}`;
    const processedOptions = newFilter.options.map((option) => ({
      ...option,
      id: `temp_${Date.now()}_${Math.random()}`,
      _isNew: true,
    }));

    const newFilterWithId = {
      ...newFilter,
      id: tempId,
      displayOrder: editedItem.filters.length,
      options: processedOptions,
    };

    // Update local state
    setEditedItem((prevItem) => ({
      ...prevItem,
      filters: [...prevItem.filters, newFilterWithId],
    }));

    // Track for API
    setModifiedFilters((prevFilters) => ({
      ...prevFilters,
      [tempId]: newFilterWithId,
    }));

    setIsAddFilterDialogOpen(false);
  };

  const handleOnSaveFilter = (savedFilter, isExistingFilter) => {
    if (isExistingFilter) {
      const existingFilter = editedItem.filters.find(
        (f) => f.id === savedFilter.id
      );

      // Compare and only mark changed options as modified
      const updatedOptions = savedFilter.options.map((option) => {
        if (!option.id) {
          // This is a new option
          return {
            ...option,
            id: `temp_${Date.now()}_${Math.random()}`,
            _isNew: true,
          };
        }

        // Find existing option to compare
        const existingOption = existingFilter.options.find(
          (opt) => opt.id === option.id
        );
        const isModified =
          existingOption &&
          (existingOption.name !== option.name ||
            parseFloat(existingOption.price) !== parseFloat(option.price));

        return {
          ...option,
          _modified: isModified,
        };
      });

      const filterWithOrder = {
        ...savedFilter,
        displayOrder: existingFilter ? existingFilter.displayOrder : 0,
        options: updatedOptions,
      };

      // Check if core filter properties changed
      const isFilterModified =
        existingFilter.name !== savedFilter.name ||
        existingFilter.isMultiSelect !== savedFilter.isMultiSelect ||
        existingFilter.isMandatory !== savedFilter.isMandatory;

      // Update local state
      setEditedItem((prevItem) => ({
        ...prevItem,
        filters: prevItem.filters.map((filter) =>
          filter.id === filterWithOrder.id ? filterWithOrder : filter
        ),
      }));

      // Update modified filters tracking
      setModifiedFilters((prevModifiedFilters) => ({
        ...prevModifiedFilters,
        [filterWithOrder.id]: {
          ...filterWithOrder,
          _filterModified: isFilterModified,
          options: updatedOptions.filter((opt) => opt._modified || opt._isNew),
          _deletedOptions: savedFilter._deletedOptions || [],
        },
      }));
    } else {
      handleAddFilter(savedFilter);
    }

    setIsAddFilterDialogOpen(false);
    setEditingFilter(null);
  };

  const handleDeleteFilter = (filterId) => {
    const filter = editedItem.filters.find((f) => f.id === filterId);

    // Remove from UI immediately
    setEditedItem((prevItem) => ({
      ...prevItem,
      filters: prevItem.filters.filter((filter) => filter.id !== filterId),
    }));

    // If it's a temporary filter, just remove it from state
    if (filterId.startsWith('temp_')) {
      setModifiedFilters((prev) => {
        const { [filterId]: _, ...rest } = prev;
        return rest;
      });
    } else {
      // For existing filters, mark as deleted and track options for cleanup
      setModifiedFilters((prev) => ({
        ...prev,
        [filterId]: {
          _deleted: true,
          _deletedOptions: filter.options.map((opt) => opt.id),
        },
      }));
    }
  };

  const handleEditOrderClick = () => {
    setIsFilterOrderPopupOpen(true);
  };

  const handleFilterOrderClose = () => {
    setIsFilterOrderPopupOpen(false);
  };

  const handleSave = () => {
    if (validateForm()) {
      onClose();
      setIsSaving(true); // Start loading state
      const formData = new FormData();

      // Add main fields to formData
      for (const key in editedItem) {
        if (key !== 'id' && key !== 'filters' && key !== 'image') {
          formData.append(key, editedItem[key]);
        }
      }

      if (editedItem.id) {
        formData.append('id', editedItem.id);
      }

      if (imageFile) {
        formData.append('image', imageFile);
      }

      // Clean up modifiedFilters to only include actually modified data
      const cleanedModifiedFilters = Object.entries(modifiedFilters).reduce(
        (acc, [filterId, filter]) => {
          if (filter._deleted) {
            acc[filterId] = { _deleted: true };
            return acc;
          }

          const cleanFilter = {
            ...filter,
            options:
              filter.options?.filter((opt) => opt._isNew || opt._modified) ||
              [],
            _deletedOptions: filter._deletedOptions || [],
          };

          // Only include filter if it has modifications
          if (
            filter._filterModified ||
            cleanFilter.options.length > 0 ||
            cleanFilter._deletedOptions.length > 0
          ) {
            acc[filterId] = cleanFilter;
          }

          return acc;
        },
        {}
      );

      const isNewItem = !editedItem.id;

      onSave(formData, isNewItem, cleanedModifiedFilters).then((success) => {
        if (success) {
          setModifiedFilters({});
          setErrors({});
          setIsSaving(false);
          onClose();
        } else {
          setIsSaving(false);
        }
      });
    }
  };

  const sortedFilters = editedItem.filters
    ? [...editedItem.filters].sort((a, b) => a.displayOrder - b.displayOrder)
    : [];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          position: isMobile ? 'absolute' : 'relative',
          bottom: isMobile ? 0 : 'auto',
          width: '100%',
          margin: 0,
          borderRadius: isMobile ? '16px 16px 0 0' : '16px',
          maxHeight: '85vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: isMobile ? 'hidden' : 'auto',
        },
      }}
    >
      {isSaving ? (
        <></>
      ) : (
        <>
          <DialogContent
            sx={{
              padding: '0 !important',
              overflowY: 'auto',
              direction: 'rtl',
              flexGrow: 1,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '&::-webkit-scrollbar-track': {
                display: 'none',
              },
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              style={{
                position: 'absolute',
                left: 26,
                top: 16,
                fontSize: '20px',
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                borderRadius: '50%',
                padding: '4px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>

            <Box
              onClick={handleImageClick}
              style={{
                width: '100%',
                height: '50vw',
                maxHeight: '300px',
                position: 'relative',
                cursor: 'pointer',
              }}
            >
              <img
                src={editedItem.image || ImagePlaceholder}
                alt={
                  editedItem.image
                    ? `תמונה של ${item.name}`
                    : 'תמונת מוצר דיפולטיבית'
                }
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              {!editedItem.image && (
                <Box
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    color: 'white',
                    fontSize: '1.2rem',
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}
                  >
                    לחץ להעלאת תמונה
                  </Typography>
                </Box>
              )}
            </Box>

            <div style={{ padding: '0 1rem' }}>
              <input
                type="file"
                id="fileInput"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept="image/*"
              />

              <Box sx={{ display: 'grid' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    direction: 'rtl',
                  }}
                >
                  <EditableField
                    value={editedItem.name}
                    onChange={(value) => handleInputChange('name', value)}
                    prompt="שם המוצר"
                    sx={{
                      fontSize: '1.75rem',
                      fontWeight: 700,
                      wordBreak: 'break-all',
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      minWidth: '10rem',
                      '& .MuiFormControlLabel-root': { marginRight: '8px' },
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={editedItem.isActive}
                          onChange={handleSwitchChange('isActive')}
                          color="primary"
                        />
                      }
                      label={
                        editedItem.isActive ? 'מוצג בתפריט' : 'לא מוצג בתפריט'
                      }
                    />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={editedItem.isAvailable}
                          onChange={handleSwitchChange('isAvailable')}
                          color="primary"
                        />
                      }
                      label={
                        editedItem.isAvailable ? 'זמין במלאי' : 'לא זמין במלאי'
                      }
                    />
                  </Box>
                </Box>
                {errors.name && (
                  <Typography
                    color="error"
                    variant="caption"
                    sx={{ fontSize: '1rem' }}
                  >
                    {errors.name}
                  </Typography>
                )}
                <EditableField
                  value={editedItem.description}
                  onChange={(value) => handleInputChange('description', value)}
                  prompt="תיאור המוצר"
                  sx={{
                    fontSize: '1.2rem',
                    fontWeight: 400,
                  }}
                />
                <EditableField
                  value={editedItem.price}
                  onChange={(value) => handleInputChange('price', value)}
                  prompt="הכנס מחיר"
                  variant="menuPricePopup"
                  isPrice={true}
                  style={{
                    fontSize: theme.typography.menuPricePopup.fontSize,
                    color: theme.palette.primary.main,
                    fontWeight: theme.typography.menuPricePopup.fontWeight,
                  }}
                />
                {errors.price && (
                  <Typography
                    color="error"
                    variant="caption"
                    sx={{ fontSize: '1rem' }}
                  >
                    {errors.price}
                  </Typography>
                )}
                <CategoryDropdown
                  categories={categories}
                  style={{ zIndex: 1300 }}
                  selectedCategory={editedItem.category}
                  onCategoryChange={(value) =>
                    handleInputChange('category', value)
                  }
                />
                {errors.category && (
                  <Typography
                    color="error"
                    variant="caption"
                    sx={{ fontSize: '1rem' }}
                  >
                    {errors.category}
                  </Typography>
                )}
              </Box>

              <Divider
                orientation="horizontal"
                variant="middle"
                sx={{ margin: '1rem 0' }}
              />

              <Typography variant="h6" gutterBottom>
                מסננים
              </Typography>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '1rem',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => setIsAddFilterDialogOpen(true)}
                  sx={{
                    marginBottom: '1rem',
                    padding: '0.5rem',
                    marginLeft: '1rem',
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? 'rgb(34 34 34)'
                        : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        theme.palette.mode === 'dark'
                          ? 'rgb(30 30 30)'
                          : 'inherit',
                    },
                  }}
                >
                  הוספת מסנן
                  <AddIcon fontSize="small" style={{ marginRight: '0.5rem' }} />
                </Button>

                <Button
                  variant="outlined"
                  onClick={handleEditOrderClick}
                  sx={{
                    marginBottom: '1rem',
                    padding: '0.5rem',
                    marginLeft: '1rem',
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? 'rgb(34 34 34)'
                        : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        theme.palette.mode === 'dark'
                          ? 'rgb(30 30 30)'
                          : 'inherit',
                    },
                  }}
                >
                  עריכת סדר תצוגת מסננים
                  <DragIndicator
                    fontSize="small"
                    style={{ marginRight: '0.5rem' }}
                  />
                </Button>
              </div>

              {sortedFilters.length > 0 ? (
                sortedFilters.map((filter) => (
                  <Grid flexDirection={'column'} key={filter.id} mb={3}>
                    <FormControl
                      component="fieldset"
                      key={filter.id}
                      style={{
                        width: '100%',
                      }}
                    >
                      <FormLabel
                        component="legend"
                        sx={{
                          color: 'inherit',
                          '&.Mui-focused': {
                            color: 'inherit',
                          },
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <IconButton
                            onClick={() => handleDeleteFilter(filter.id)}
                            sx={{
                              color: 'red',
                              alignSelf: 'flex-end',
                              padding: '0',
                              marginLeft: '0.2rem',
                            }}
                          >
                            <DeleteForeverIcon sx={{ fontSize: '2rem' }} />
                          </IconButton>
                          <IconButton onClick={() => handleEditFilter(filter)}>
                            <EditIcon />
                          </IconButton>
                          <Typography variant="menuName">
                            {filter.name}
                          </Typography>
                        </Box>
                      </FormLabel>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingLeft: '0.5rem',
                        }}
                      >
                        {filter.options && Array.isArray(filter.options) ? (
                          filter.options.map((option) => (
                            <div
                              key={option.id || `${filter.id}-${option.name}`}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                direction: 'ltr',
                              }}
                            >
                              <div>
                                <Typography
                                  variant="descMenuPopup"
                                  color="text.secondary"
                                  style={{
                                    flex: 1,
                                    textAlign: 'left',
                                  }}
                                >
                                  {option.price}
                                </Typography>
                              </div>
                              <FormControlLabel
                                control={<Checkbox disabled />}
                                label={
                                  <Typography
                                    variant="descMenuPopup"
                                    color="text.secondary"
                                    style={{
                                      width: '100%',
                                      textAlign: 'right',
                                    }}
                                  >
                                    {option.name}
                                  </Typography>
                                }
                                labelPlacement="start"
                                sx={{
                                  margin: '0',
                                  flexGrow: 1,
                                  justifyContent: 'flex-start',
                                  textAlign: 'right',
                                }}
                              />
                            </div>
                          ))
                        ) : (
                          <Typography variant="caption" color="error">
                            No options available
                          </Typography>
                        )}
                      </div>
                    </FormControl>
                  </Grid>
                ))
              ) : (
                <Typography>אין מסננים למוצר זה</Typography>
              )}
            </div>
          </DialogContent>

          {manageStock && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                direction: 'rtl',
                padding: '0.5rem',
                height: '2rem',
                maxHeight: '2rem',
                margin: 0,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  marginRight: '0.15rem',
                  fontWeight: 'normal',
                  marginLeft: 0,
                  minWidth: 'fit-content',
                  fontSize: '1.25rem',
                  lineHeight: '2rem',
                }}
              >
                כמות במלאי:
              </Typography>
              <Box
                sx={{
                  width: '2.8rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '1.75rem',
                  maxHeight: '1.75rem',
                  position: 'relative',
                  marginRight: '-1.5rem',
                }}
              >
                <EditableField
                  value={editedItem.quantityInStock}
                  prompt="0"
                  onChange={(value) =>
                    handleInputChange('quantityInStock', value)
                  }
                  isNumeric={true}
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    padding: 0,
                    margin: 0,
                    textAlign: 'center',
                    lineHeight: '1.75rem',
                    height: '1.75rem',
                    color: theme.palette.secondary.main,
                    '& input': {
                      padding: 0,
                      margin: 0,
                      lineHeight: '1.75rem',
                      height: '1.75rem',
                      textAlign: 'center',
                      fontSize: '1.5rem',
                      width: '100%',
                      color: theme.palette.secondary.main,
                    },
                  }}
                />
              </Box>
            </Box>
          )}

          <DialogActions
            style={{
              padding: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 'auto',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              style={{
                width: '100%',
                color: '#fff',
                height: '48px',
                fontSize: '1rem',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              }}
            >
              <Typography variant="menuPricePopup">שמור שינויים</Typography>
            </Button>
          </DialogActions>
        </>
      )}

      {/* ImageCropper Dialog */}
      {isCropperOpen && (
        <Dialog
          open={isCropperOpen}
          onClose={() => setIsCropperOpen(false)}
          fullWidth
          maxWidth="sm"
          sx={{
            '& .MuiDialog-paper': {
              maxHeight: '90vh',
            },
          }}
        >
          <DialogTitle>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0',
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setIsCropperOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="titlePopup"
                sx={{ flexGrow: 1, textAlign: 'right' }}
              >
                מומלץ למקם את המוצר בתור העיגול המסומן
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              maxHeight: 'calc(90vh - 64px)',
            }}
          >
            <ImageCropper
              src={croppedImage}
              onCrop={handleCrop}
              aspectRatio={2 / 1}
            />
          </DialogContent>
        </Dialog>
      )}

      {/* Filter Order Popup */}
      {isFilterOrderPopupOpen && (
        <FilterOrderPopup
          open={isFilterOrderPopupOpen}
          onClose={handleFilterOrderClose}
          filters={sortedFilters}
          onUpdateFilters={handleUpdateFiltersOrder}
        />
      )}

      {/* Add Filter Popup */}
      <AddFilterPopup
        open={isAddFilterDialogOpen}
        onClose={() => {
          setIsAddFilterDialogOpen(false);
          setEditingFilter(null);
        }}
        onAddFilter={handleOnSaveFilter}
        editingFilter={editingFilter}
      />
    </Dialog>
  );
};

export default MenuItemPopupDashItem;

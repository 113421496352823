import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import MenuItemDash from './MenuItemDashItem';
import MenuItemPopupDashItem from './MenuItemPopUpDashItem';
import {
  selectManagerMenu,
  selectManagerStatus,
} from '../../../features/menu/managerMenuSlice';
import CategoryPopup from '../Category/CategoryPopup'; // Import the combined component
import AddIcon from '@mui/icons-material/Add';
import { selectCategories } from '../../../features/category/CategorySlice';
import {
  addMenuItem,
  editMenuItem,
} from '../../../features/menu/managerMenuThunks';
import {
  addFilter,
  deleteFilterThunk,
  updateFilterThunk,
} from '../../../features/filter/filterThunk';
import {
  updateCategoryThunk,
  addCategoryThunk,
  deleteCategoryThunk,
} from '../../../features/category/CategoryThunk';
import {
  addFilterOptionThunk,
  deleteFilterOptionThunk,
  updateFilterOptionThunk,
} from '../../../features/filterOptions/filterOptionThunk';
import ConfirmDialog from '../../core/confirmDialog';
import SpinLoader from '../../core/SpinLoader';
import MenuPDFDownload from '../utils/MenuPDFDownload';
import { fetchManagerMenuItems } from '../../../features/menu/managerMenuThunks';
import { fetchCategories } from '../../../features/category/CategoryThunk';
import MenuItemsOrderPopup from './MenuItemsOrderPopup';

/** The main component for the menu items dashboard, displays all the items */
const MenuItemsDashboard = () => {
  const dispatch = useDispatch();
  const menuItems = useSelector(selectManagerMenu); // Fetch menu items from the Redux store
  const status = useSelector(selectManagerStatus);
  const [categories, setCategories] = useState([]);
  const allCategories = useSelector(selectCategories); // Fetch categories from Redux
  const [isNewItemPopupOpen, setIsNewItemPopupOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const isLoading = useSelector(
    (state) =>
      state.managerMenu.status === 'loading' ||
      state.filters.status === 'loading' ||
      state.categories.status === 'loading'
  );
  const [orderPopupAnchorEl, setOrderPopupAnchorEl] = useState(null);
  const [selectedCategoryItems, setSelectedCategoryItems] = useState([]);

  useEffect(() => {
    console.log('Menu items fetched from Redux:', menuItems);
    // Add logs for any transformation or filtering logic here
  }, [menuItems]);

  useEffect(() => {
    if (allCategories.length > 0) {
      const uniqueCategories = allCategories.map((category) => ({
        id: category.id,
        name: category.name,
        displayOrder: category.displayOrder,
      }));
      const sortedCategories = uniqueCategories.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
      setCategories(sortedCategories);
    }
  }, [allCategories]);

  const handleCategoriesUpdate = useCallback((updatedCategories) => {
    setCategories(updatedCategories);
  }, []);

  const handleSaveCategories = async (updatedCategories) => {
    await Promise.all(
      updatedCategories.map((category) => {
        if (category.id.startsWith('temp_')) {
          // Dispatch addCategoryThunk for new categories
          return dispatch(addCategoryThunk(category));
        } else {
          // Dispatch updateCategoryThunk for existing categories
          return dispatch(updateCategoryThunk(category));
        }
      })
    );
    dispatch(fetchManagerMenuItems());
    dispatch(fetchCategories());
  };

  const handleDeleteCategory = (category) => {
    setCategoryToDelete(category);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    const result = await dispatch(deleteCategoryThunk(categoryToDelete));
    if (deleteCategoryThunk.fulfilled.match(result)) {
      setCategories((prevCategories) =>
        prevCategories.filter((category) => category.id !== categoryToDelete)
      );
    }
    setCategoryToDelete(null);
    setDeleteConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setCategoryToDelete(null);
  };

  const handleCancelChanges = (orginalState) => {
    dispatch(fetchManagerMenuItems());
    dispatch(fetchCategories());
  };

  const handleOpenNewItemPopup = () => {
    if (!categories || categories.length === 0) {
      setIsErrorDialogOpen(true);
      return;
    }
    setIsNewItemPopupOpen(true);
  };

  const handleCloseNewItemPopup = () => {
    setIsNewItemPopupOpen(false);
  };

  // MenuItemsDashboard.js

  // In MenuItemPopupDashItem.js
  const handleSaveItem = async (updatedItem, isNewItem, modifiedFilters) => {
    try {
      let savedItemId = isNewItem ? null : updatedItem.get('id');

      // Handle the main item creation first for new items
      if (isNewItem) {
        const result = await dispatch(addMenuItem(updatedItem));
        if (!addMenuItem.fulfilled.match(result)) {
          throw new Error('Failed to create new item');
        }
        savedItemId = result.payload.id;
      }

      // Process all filters if they exist
      if (modifiedFilters && typeof modifiedFilters === 'object') {
        // Handle deletions first
        await Promise.all(
          Object.entries(modifiedFilters)
            .filter(([_, filterData]) => filterData._deleted)
            .map(([filterId]) => {
              if (!filterId.startsWith('temp_')) {
                return dispatch(deleteFilterThunk(filterId));
              }
              return Promise.resolve();
            })
        );

        // Handle existing filter updates sequentially
        for (const [filterId, filterData] of Object.entries(modifiedFilters)) {
          if (!filterId.startsWith('temp_') && !filterData._deleted) {
            // Handle deleted options first
            if (filterData._deletedOptions?.length) {
              await Promise.all(
                filterData._deletedOptions.map((optionId) =>
                  dispatch(deleteFilterOptionThunk(optionId))
                )
              );
            }

            // Update filter if modified
            if (filterData._filterModified) {
              const {
                options,
                _deletedOptions,
                _filterModified,
                ...filterDataWithoutOptions
              } = filterData;
              await dispatch(
                updateFilterThunk({
                  id: filterId,
                  updatedFilter: {
                    ...filterDataWithoutOptions,
                    menuItemId: savedItemId,
                  },
                })
              );
            }

            // Process options sequentially
            for (const option of filterData.options) {
              if (option._isNew) {
                await dispatch(
                  addFilterOptionThunk({
                    filterId,
                    name: option.name,
                    price: parseFloat(option.price),
                  })
                );
              } else if (
                option._modified &&
                !option._isNew &&
                !option._deleted
              ) {
                await dispatch(
                  updateFilterOptionThunk({
                    id: option.id,
                    filterOption: {
                      name: option.name,
                      price: parseFloat(option.price),
                      filterId,
                    },
                  })
                );
              }
            }
          }
        }

        // Handle new filters sequentially
        for (const [filterId, filterData] of Object.entries(modifiedFilters)) {
          if (filterId.startsWith('temp_')) {
            const {
              options,
              _deletedOptions,
              id,
              _filterModified,
              ...filterDataWithoutOptions
            } = filterData;

            // Create the new filter
            const filterResult = await dispatch(
              addFilter({
                ...filterDataWithoutOptions,
                menuItemId: savedItemId,
              })
            ).unwrap();

            // Add options for the new filter sequentially
            for (const option of options) {
              await dispatch(
                addFilterOptionThunk({
                  filterId: filterResult.id,
                  name: option.name,
                  price: parseFloat(option.price),
                })
              );
            }
          }
        }
      }

      // Update the main item last if it's an existing item
      if (!isNewItem) {
        const result = await dispatch(
          editMenuItem({ id: savedItemId, updatedItem })
        );
        if (!editMenuItem.fulfilled.match(result)) {
          throw new Error('Failed to update item');
        }
      }

      return true;
    } catch (error) {
      console.error('Error in handleSaveItem:', error);
      return false;
    }
  };

  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
  };

  // Group menu items by category
  const groupedItems = Array.isArray(categories)
    ? categories.map((category) => {
        const itemsInCategory = menuItems
          .filter((item) => item.category && item.category.id === category.id)
          // Sort items by displayOrder
          .sort((a, b) => a.displayOrder - b.displayOrder);

        return {
          ...category,
          items: itemsInCategory,
          editButton: (
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleEditCategoryOrder(category.id)}
              sx={{ mr: 2, direction: 'rtl' }}
            >
              עריכת סדר מוצרים
            </Button>
          ),
        };
      })
    : [];

  const handleEditCategoryOrder = (categoryId) => {
    const category = groupedItems.find((cat) => cat.id === categoryId);
    if (category && category.items) {
      setSelectedCategoryItems(category.items);
      setOrderPopupAnchorEl(document.activeElement);
    }
  };

  const handleSaveItemOrder = async (updatedItems) => {
    try {
      await Promise.all(
        updatedItems.map((item) => {
          const itemData = new FormData();

          Object.entries(item).forEach(([key, value]) => {
            if (key !== 'filters' && key !== '__v') {
              if (key === 'category') {
                // Send only category ID with the correct field name
                itemData.append('categoryId', value.id);
              } else if (typeof value === 'object' && value !== null) {
                itemData.append(key, JSON.stringify(value));
              } else {
                itemData.append(key, value);
              }
            }
          });

          return dispatch(
            editMenuItem({
              id: item.id,
              updatedItem: itemData,
            })
          );
        })
      );

      setOrderPopupAnchorEl(null);
    } catch (error) {
      console.error('Failed to update items order:', error);
    }
  };

  // Handle loading and error states
  if (status === 'loading') {
    return <SpinLoader />;
  }

  if (status === 'failed') {
    return <div>Failed to load menu items.</div>;
  }

  return (
    <Box sx={{ padding: 2 }}>
      {/* Organize {add new dish},{change category order} */}
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: '2rem',
          justifyContent: 'flex-end',
        }}
      >
        {isLoading && (
          <Grid item xs={12}>
            <SpinLoader />
          </Grid>
        )}
        <Grid item xs={6} md={3}>
          <MenuPDFDownload />
        </Grid>
        <Grid item xs={6} md={3}>
          <CategoryPopup
            categories={Array.isArray(categories) ? categories : []}
            onCategoriesUpdate={handleCategoriesUpdate}
            onSaveCategories={handleSaveCategories}
            onCancelChanges={handleCancelChanges}
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenNewItemPopup}
            startIcon={<AddIcon />}
            sx={{
              alignSelf: 'flex-start',
              minWidth: '200px',
              borderRadius: '0.5rem',
              height: '3rem',
              margin: 0, // Remove default margin
            }}
          >
            הוסף מנה חדשה
          </Button>
        </Grid>
      </Grid>

      {groupedItems
        .sort((a, b) => a.displayOrder - b.displayOrder) // Sort categories by displayOrder
        .map((category) => (
          <Box key={category.id} sx={{ marginBottom: 4, textAlign: 'right' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                onClick={() => handleDeleteCategory(category.id)}
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: 'red',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#d32f2f',
                  },
                  mr: 2,
                }}
              >
                מחק קטגוריה
              </Button>
              {category.editButton}
              <Typography variant="menuTitle" sx={{ marginRight: 1 }}>
                {category.name}
              </Typography>
            </Box>
            <Grid container spacing={2} mt={1} direction="row-reverse">
              {category.items && category.items.length > 0 ? (
                category.items.map((item) => (
                  <Grid
                    size={{ xs: 12, sm: 6, md: 4, xl: 3 }}
                    key={item.id}
                    sx={{ direction: 'rtl' }}
                  >
                    <MenuItemDash item={item} onSaveEditItem={handleSaveItem} />
                  </Grid>
                ))
              ) : (
                <Typography variant="body1">אין מוצרים בקטגוריה זו</Typography>
              )}
            </Grid>
          </Box>
        ))}
      <MenuItemPopupDashItem
        open={isNewItemPopupOpen}
        onClose={handleCloseNewItemPopup}
        onSave={handleSaveItem}
        item={{}} // Pass an empty object for a new item
        categories={allCategories} // Pass all categories
      />
      <MenuItemsOrderPopup
        items={selectedCategoryItems}
        open={Boolean(orderPopupAnchorEl)}
        anchorEl={orderPopupAnchorEl}
        onClose={() => setOrderPopupAnchorEl(null)}
        onSaveOrder={handleSaveItemOrder}
      />
      <ConfirmDialog
        open={deleteConfirmOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="האם אתה בטוח שברצונך למחוק קטגוריה זו?"
        description="פעולה זו תמחק את הקטגוריה ואת כל המוצרים שבתוכה לצמיתות. האם אתה בטוח שברצונך להמשיך?"
      />
      <ConfirmDialog
        open={isErrorDialogOpen}
        onClose={handleCloseErrorDialog}
        onConfirm={handleCloseErrorDialog}
        title="לא ניתן להוסיף מנה"
        description="עליך ליצור לפחות קטגוריה אחת לפני הוספת מנה חדשה"
        confirmText="הבנתי"
        isShowDeleteBtn={false}
      />
    </Box>
  );
};

export default MenuItemsDashboard;

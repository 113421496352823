import axios from 'axios';

/* eslint-disable no-unused-vars */
const render_URL = 'https://api.olivercoffeecart.online/api/filters/options';
const local_URL = 'http://localhost:3001/api/filters/options';
/* eslint-enable no-unused-vars */
const BASE_URL =
  process.env.REACT_APP_NODE_ENV === 'production' ? render_URL : local_URL;

// Create axios instance with credentials
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// Fetch all filter options
export const getFilterOptions = async () => {
  const response = await api.get(BASE_URL);
  return response.data;
};

// Fetch a single filter option by ID
export const getFilterOption = async (id) => {
  const response = await api.get(BASE_URL + `/${id}`);
  return response.data;
};

// Create a new filter option with filter ID
export const createFilterOption = async (filterOption) => {
  const response = await api.post(BASE_URL, filterOption);
  return response.data;
};

// Update a filter option
export const updateFilterOption = async (id, filterOption) => {
  const response = await api.put(BASE_URL + `/${id}`, filterOption);
  return response.data;
};

// Delete a filter option
export const deleteFilterOption = async (id) => {
  const response = await api.delete(BASE_URL + `/${id}`);
  return response.data;
};

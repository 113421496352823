import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Container,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { alpha } from '@mui/material/styles';
import { useSelector } from 'react-redux'; // Redux support
import { selectConfiguration } from '../../features/configuration/configurationSlice'; // Redux selector

const Footer = ({ workingHours = [] }) => {
  const theme = useTheme();
  const currentYear = new Date().getFullYear();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  // Redux: Fetch dynamic configurations
  const configurations = useSelector(selectConfiguration);
  const config = configurations[0] || {};
  const { phone, email, address, facebook, instagram, placeName, logo } =
    config;

  const footerBgColor =
    theme.palette.mode === 'light'
      ? alpha(theme.palette.grey[200], 0.8)
      : alpha(theme.palette.grey[900], 0.5);

  const footerBottomColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[300]
      : theme.palette.grey[800];

  const daysInHebrew = [
    'ראשון',
    'שני',
    'שלישי',
    'רביעי',
    'חמישי',
    'שישי',
    'שבת',
  ];

  const handlePhoneClick = (phone) => {
    window.location.href = `tel:${phone}`;
  };

  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const handleAddressClick = async (address) => {
    window.open(
      `https://waze.com/ul?q=${encodeURIComponent(address)}`
    );
  };

  const formatHours = (shifts) => {
    if (!shifts || shifts.length === 0) return 'סגור';
    return shifts
      .map((shift) => `${shift.closeTime} - ${shift.openTime}`)
      .join(', ');
  };

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: footerBgColor,
        color: 'text.primary',
        pt: 1,
        mt: 'auto',
        backdropFilter: 'blur(8px)',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Container sx={{ width: '100%' }}>
            <Grid
              container
              spacing={4}
              direction="row-reverse"
              alignItems="center"
            >
              {/* Footer Content */}
              <Grid size={{ xs: 12, md: 12 }}>
                <Grid container spacing={3} direction="row-reverse">
                  {/* Contact Info */}
                  <Grid size={{ xs: 12, md: 3 }} sx={{ textAlign: 'right' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 1,
                        color:
                          theme.palette.mode === 'light'
                            ? 'grey.800'
                            : 'grey.100',
                      }}
                    >
                      צור קשר
                    </Typography>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        gap: 1,
                        alignItems: 'flex-end',
                        color: 'text.secondary',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          '&:hover': {
                            textDecoration: 'underline',
                            color: 'primary.main',
                          },
                          transition: 'color 0.2s',
                        }}
                        onClick={() => handlePhoneClick(phone)}
                        aria-label="התקשר למספר"
                      >
                        <Typography>{phone || 'לא זמין'}</Typography>
                        <PhoneIcon fontSize="small" />
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          '&:hover': {
                            textDecoration: 'underline',
                            color: 'primary.main',
                          },
                          transition: 'color 0.2s',
                        }}
                        onClick={() => handleEmailClick(email)}
                        aria-label="שלח אימייל"
                      >
                        <Typography>{email || 'לא זמין'}</Typography>
                        <EmailIcon fontSize="small" />
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          '&:hover': {
                            textDecoration: 'underline',
                            color: 'primary.main',
                          },
                          transition: 'color 0.2s',
                        }}
                        onClick={() => handleAddressClick(address)}
                        aria-label="נווט לכתובת"
                      >
                        <Typography>{address || 'לא זמין'}</Typography>
                        <LocationOnIcon fontSize="small" />
                      </Box>
                    </Box>
                  </Grid>

                  {/* Opening Hours */}
                  <Grid size={{ xs: 12, md: 3 }} sx={{ textAlign: 'right' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 1,
                        color:
                          theme.palette.mode === 'light'
                            ? 'grey.800'
                            : 'grey.100',
                      }}
                    >
                      שעות פעילות
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        alignItems: 'flex-end',
                        color: 'text.secondary',
                      }}
                    >
                      {daysInHebrew.map((day, index) => (
                        <Typography key={index}>
                          {day}: {formatHours(workingHours[index]) || '10:00'}
                        </Typography>
                      ))}
                    </Box>
                  </Grid>

                  {/* Social Links */}
                  <Grid
                    size={{ xs: 12, md: 3 }}
                    sx={{ direction: 'rtl', textAlign: 'right' }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 1,
                        color:
                          theme.palette.mode === 'light'
                            ? 'grey.800'
                            : 'grey.100',
                      }}
                    >
                      עקבו אחרינו
                    </Typography>
                    <Box>
                      {instagram && (
                        <IconButton
                          component="a"
                          href={instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ color: 'text.secondary' }}
                        >
                          <InstagramIcon />
                        </IconButton>
                      )}
                      {facebook && (
                        <IconButton
                          component="a"
                          href={facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ color: 'text.secondary' }}
                        >
                          <FacebookIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Grid>
                  {/* Essential Links */}
                  <Grid
                    size={{ xs: 12, md: 3 }}
                    sx={{ direction: 'rtl', textAlign: 'right' }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 1,
                        color:
                          theme.palette.mode === 'light'
                            ? 'grey.800'
                            : 'grey.100',
                      }}
                    >
                      לינקים חיוניים
                    </Typography>
                    <Box>
                      <Link
                        to="/accessibility"
                        style={{
                          color: theme.palette.text.secondary,
                          textDecoration: 'underline',
                        }}
                      >
                        הצהרת נגישות
                      </Link>{' '}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        {isDesktop && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '2rem',
              marginRight: '2rem',
            }}
          >
            <Box
              sx={{
                width: '2px',
                height: '90%',
                backgroundColor: theme.palette.divider,
              }}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '1rem',
                '& img': {
                  filter:
                    theme.palette.mode === 'dark'
                      ? 'invert(1) brightness(0.8)'
                      : 'none',
                },
              }}
            >
              <img
                src={logo}
                alt="Oliver"
                style={{
                  width: '10rem',
                  height: 'auto',
                  marginLeft: '2rem',
                }}
              />
            </Box>
          </Box>
        )}
      </Box>

      {/* Bottom Bar */}
      <Box
        sx={{
          bgcolor: footerBottomColor,
          textAlign: 'center',
          marginTop: '2rem',
          direction: 'rtl',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
          }}
        >
          כל הזכויות שמורות ל{placeName || 'שם העסק'} {currentYear}. ©
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  fetchManagerMenuItems,
  addMenuItem,
  editMenuItem,
  removeMenuItem,
} from './managerMenuThunks';
import {
  deleteFilterThunk,
  addFilter,
  updateFilterThunk,
} from '../filter/filterThunk';
import {
  addFilterOptionThunk,
  updateFilterOptionThunk,
  deleteFilterOptionThunk,
} from '../filterOptions/filterOptionThunk';
import {
  updateCategoryThunk,
  deleteCategoryThunk,
} from '../category/CategoryThunk';

const managerMenuSlice = createSlice({
  name: 'managerMenu',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
    changesPending: false,
  },
  reducers: {
    // Add a reducer to update filter options locally
    updateFilterOptionsLocally(state, action) {
      const { menuItemId, filterId, updatedOptions } = action.payload;

      state.items = state.items.map((item) => {
        if (item.id === menuItemId) {
          return {
            ...item,
            filters: item.filters.map((filter) => {
              if (filter.id === filterId) {
                return {
                  ...filter,
                  options: updatedOptions,
                };
              }
              return filter;
            }),
          };
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Menu Items
      .addCase(fetchManagerMenuItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchManagerMenuItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchManagerMenuItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Add Menu Item
      .addCase(addMenuItem.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addMenuItem.fulfilled, (state, action) => {
        state.status = 'success';
        state.items.push(action.payload);
      })
      .addCase(addMenuItem.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Edit Menu Item
      .addCase(editMenuItem.pending, (state) => {
        state.changesPending = true;
        state.status = 'loading';
      })
      .addCase(editMenuItem.fulfilled, (state, action) => {
        state.changesPending = false;
        state.status = 'success';
        const index = state.items.findIndex(
          (item) => item.id === action.payload.id
        );

        if (index !== -1) {
          // Merge the existing item with the updated payload, preserving filters
          state.items[index] = {
            ...state.items[index],
            ...action.payload,
            filters: state.items[index].filters, // Keep the existing filters intact
          };
        }
      })
      .addCase(editMenuItem.rejected, (state, action) => {
        state.changesPending = false;
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Remove Menu Item
      .addCase(removeMenuItem.pending, (state) => {
        state.changesPending = true;
        state.status = 'loading';
      })
      .addCase(removeMenuItem.fulfilled, (state, action) => {
        state.changesPending = false;
        state.status = 'success';
        state.items = state.items.filter((item) => item.id !== action.payload);
      })
      .addCase(removeMenuItem.rejected, (state, action) => {
        state.changesPending = false;
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Add Filter
      .addCase(addFilter.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addFilter.fulfilled, (state, action) => {
        state.items = state.items.map((item) => {
          if (item.id === action.payload.menuItemId) {
            return {
              ...item,
              filters: [
                ...(item.filters ? [...item.filters] : []), // Ensure existing filters are preserved
                { ...action.payload, options: [] }, // Add the new filter
              ],
            };
          }
          return item;
        });
        state.status = 'success';
      })
      .addCase(addFilter.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Update Filter
      .addCase(updateFilterThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateFilterThunk.fulfilled, (state, action) => {
        state.status = 'success';
        state.items = state.items.map((item) => {
          const updatedFilters = (item.filters || []).map((filter) => {
            if (filter.id === action.payload.id) {
              return {
                ...filter,
                ...action.payload,
                options: filter.options || [], // Preserve existing options
              };
            }
            return filter;
          });
          return {
            ...item,
            filters: updatedFilters,
          };
        });
      })
      .addCase(updateFilterThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Delete Filter
      .addCase(deleteFilterThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteFilterThunk.fulfilled, (state, action) => {
        state.status = 'failed';
        state.items = state.items.map((item) => ({
          ...item,
          filters: (item.filters || []).filter(
            (filter) => filter.id !== action.payload
          ),
        }));
      })
      .addCase(deleteFilterThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Add Filter Option
      .addCase(addFilterOptionThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addFilterOptionThunk.fulfilled, (state, action) => {
        state.status = 'success';
        const { filterId } = action.payload;
        state.items = state.items.map((item) => {
          const updatedFilters = item.filters?.map((filter) => {
            if (filter.id === filterId) {
              const newOption = {
                id: action.payload.id,
                name: action.payload.name,
                price: action.payload.price,
              };

              // Check if option already exists
              const exists = filter.options.some(
                (opt) => opt.id === newOption.id
              );
              if (!exists) {
                return {
                  ...filter,
                  options: [...(filter.options || []), newOption],
                };
              }
            }
            return filter;
          });

          if (updatedFilters) {
            return {
              ...item,
              filters: updatedFilters,
            };
          }
          return item;
        });
      })
      .addCase(addFilterOptionThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Update Filter Option
      .addCase(updateFilterOptionThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateFilterOptionThunk.fulfilled, (state, action) => {
        state.status = 'success';
        state.items = state.items.map((item) => {
          const updatedFilters = item.filters?.map((filter) => {
            if (filter.id === action.payload.filterId) {
              return {
                ...filter,
                options: filter.options.map((option) =>
                  option.id === action.payload.id
                    ? {
                        id: action.payload.id,
                        name: action.payload.name,
                        price: action.payload.price,
                      }
                    : option
                ),
              };
            }
            return filter;
          });

          if (updatedFilters) {
            return {
              ...item,
              filters: updatedFilters,
            };
          }
          return item;
        });
      })
      .addCase(updateFilterOptionThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Delete Filter Option
      .addCase(deleteFilterOptionThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteFilterOptionThunk.fulfilled, (state, action) => {
        state.status = 'success';
        const deletedOptionId = action.payload; // The response is just the deleted ID
        state.items = state.items.map((item) => {
          const updatedFilters = item.filters?.map((filter) => ({
            ...filter,
            options: filter.options.filter((opt) => opt.id !== deletedOptionId),
          }));

          if (updatedFilters) {
            return {
              ...item,
              filters: updatedFilters,
            };
          }
          return item;
        });
      })
      .addCase(deleteFilterOptionThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Update Category
      .addCase(updateCategoryThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCategoryThunk.fulfilled, (state, action) => {
        state.status = 'success';
        state.items = state.items.map((item) =>
          item.category?.id === action.payload.id
            ? { ...item, category: action.payload }
            : item
        );
      })
      .addCase(updateCategoryThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Delete Category
      .addCase(deleteCategoryThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCategoryThunk.fulfilled, (state, action) => {
        state.status = 'success';
        state.items = state.items.filter(
          (item) => item.category?.id !== action.payload
        );
      })
      .addCase(deleteCategoryThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Export the local update action
export const { updateFilterOptionsLocally } = managerMenuSlice.actions;

// Basic selectors
const selectManagerMenuState = (state) => state.managerMenu;

// Memoized selectors
export const selectManagerMenu = createSelector(
  [selectManagerMenuState],
  (managerMenuState) => managerMenuState.items
);

export const selectManagerStatus = createSelector(
  [selectManagerMenuState],
  (managerMenuState) => managerMenuState.status
);

export const selectMenu = (state) => state.managerMenu.items;
export const selectStatus = (state) => state.managerMenu.status;

export default managerMenuSlice.reducer;

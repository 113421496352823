import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Box,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { addToCart, updateCartItem } from '../../../features/cart/cartSlice';
import {
  motion,
  useMotionValue,
  useAnimate,
  useDragControls,
} from 'framer-motion';
import useMeasure from 'react-use-measure';
import * as globals from '../../../utils/globals';

const MenuItemPopup = ({
  open,
  onClose,
  item,
  selectedValues,
  initialQuantity,
  fromCart,
  shouldDisplayRedMessage,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [validationResults, setValidationResults] = useState({});
  const [showValidation, setShowValidation] = useState(false);
  const filterRefs = useRef({});

  // Add controls for dragging
  const controls = useDragControls();
  const y = useMotionValue(0);
  const [scope, animate] = useAnimate();
  const [drawerRef, { height }] = useMeasure();

  useEffect(() => {
    setQuantity(initialQuantity || 1);
    // Make sure we initialize with the new structure
    setSelectedFilters(selectedValues || []);
    setShowValidation(false);
  }, [item, selectedValues, initialQuantity]);

  const validateMandatoryFilters = () => {
    const results = {};
    const mandatoryFilters = item.filters.filter(
      (filter) => filter.isMandatory
    );

    for (let filter of mandatoryFilters) {
      const selectedFilter = selectedFilters.find(
        (f) => f.filterId === filter.id
      );

      if (!selectedFilter || selectedFilter.options.length === 0) {
        results[filter.id] = false;
      } else {
        results[filter.id] = true;
      }
    }
    return results;
  };

  const handleAddToCart = () => {
    const results = validateMandatoryFilters();
    setValidationResults(results);
    setShowValidation(true);
    const isValid = Object.values(results).every((value) => value);
    if (!isValid) {
      const firstInvalidFilterId = item.filters
        .filter((filter) => filter.isMandatory)
        .map((filter) => filter.id)
        .find((id) => !results[id]);
      if (firstInvalidFilterId && filterRefs.current[firstInvalidFilterId]) {
        filterRefs.current[firstInvalidFilterId].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        filterRefs.current[firstInvalidFilterId].focus();
      }
      return;
    }

    if (fromCart) {
      const updatedItem = {
        ...item,
        filterBasedId: item.filterBasedId,
        selectedValues: selectedFilters,
        quantity,
      };
      dispatch(updateCartItem(updatedItem));
    } else {
      const newItem = {
        ...item,
        quantity,
        selectedValues: selectedFilters,
      };
      dispatch(addToCart(newItem));
    }

    // Close the popup first
    handleClose();

    // Reset state after a short delay
    setTimeout(() => {
      setQuantity(1);
      setSelectedFilters([]);
    }, 300); // Delay matches the animation duration
  };

  const handleQuantityIncrease = () => {
    setQuantity((prev) => prev + 1);
  };

  const handleQuantityDecrease = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const handleFilterChange = (
    filterId,
    optionId,
    filterName,
    isMultiSelect,
    optionPrice,
    optionName,
    optionSku
  ) => {
    setSelectedFilters((prevFilters) => {
      // Find existing filter
      const existingFilterIndex = prevFilters.findIndex(
        (filter) => filter.filterId === filterId
      );

      if (isMultiSelect) {
        if (existingFilterIndex !== -1) {
          const existingFilter = prevFilters[existingFilterIndex];
          const optionIndex = existingFilter.options.findIndex(
            (opt) => opt.filterOptionId === optionId
          );

          let updatedOptions;
          if (optionIndex !== -1) {
            // Remove option if it exists
            updatedOptions = existingFilter.options.filter(
              (opt) => opt.filterOptionId !== optionId
            );
          } else {
            // Add new option
            updatedOptions = [
              ...existingFilter.options,
              {
                filterOptionId: optionId,
                filterOptionName: optionName,
                filterOptionPrice: optionPrice,
                filterOptionSku: optionSku,
              },
            ];
          }

          // If no options left, remove the filter entirely
          if (updatedOptions.length === 0) {
            return prevFilters.filter((filter) => filter.filterId !== filterId);
          }

          // Update the filter with new options
          return prevFilters.map((filter) =>
            filter.filterId === filterId
              ? { ...filter, options: updatedOptions }
              : filter
          );
        } else {
          // Add new filter with first option
          return [
            ...prevFilters,
            {
              filterId,
              filterName,
              options: [
                {
                  filterOptionId: optionId,
                  filterOptionName: optionName,
                  filterOptionPrice: optionPrice,
                  filterOptionSku: optionSku,
                },
              ],
            },
          ];
        }
      } else {
        // For single select, either update existing filter or add new one
        if (existingFilterIndex !== -1) {
          const existingFilter = prevFilters[existingFilterIndex];

          // If selecting the same option, remove the filter
          if (existingFilter.options[0]?.filterOptionId === optionId) {
            return prevFilters.filter((filter) => filter.filterId !== filterId);
          }

          // Update with new option
          return prevFilters.map((filter) =>
            filter.filterId === filterId
              ? {
                  ...filter,
                  options: [
                    {
                      filterOptionId: optionId,
                      filterOptionName: optionName,
                      filterOptionPrice: optionPrice,
                      filterOptionSku: optionSku,
                    },
                  ],
                }
              : filter
          );
        } else {
          // Add new filter with single option
          return [
            ...prevFilters,
            {
              filterId,
              filterName,
              options: [
                {
                  filterOptionId: optionId,
                  filterOptionName: optionName,
                  filterOptionPrice: optionPrice,
                  filterOptionSku: optionSku,
                },
              ],
            },
          ];
        }
      }
    });

    setValidationResults((prevResults) => ({
      ...prevResults,
      [filterId]: true,
    }));
  };

  const handleClose = async () => {
    const yStart = typeof y.get() === 'number' ? y.get() : 0;
    if (isMobile) {
      await animate(
        '#menu-item-popup',
        {
          y: [yStart, height],
        },
        {
          duration: 0.3,
          ease: 'easeOut',
        }
      );
    } else {
      await animate(
        '#menu-item-popup',
        {
          opacity: [1, 0],
        },
        {
          duration: 0.2,
          ease: 'easeOut',
        }
      );
    }
    onClose();
  };

  const calculateTotalPrice = () => {
    const basePrice = item.price;
    const selectedOptionsPrice = selectedFilters.reduce((total, filter) => {
      // Sum up all option prices in the filter's options array
      return (
        total +
        filter.options.reduce((sum, option) => {
          return sum + parseFloat(option.filterOptionPrice || 0);
        }, 0)
      );
    }, 0);
    return (basePrice + selectedOptionsPrice) * quantity;
  };

  const totalPrice = calculateTotalPrice().toFixed(2);

  const primaryColor = theme.palette.primary.main;
  const quantityBackgroundColor = `rgba(${parseInt(
    primaryColor.slice(1, 3),
    16
  )}, ${parseInt(primaryColor.slice(3, 5), 16)}, ${parseInt(
    primaryColor.slice(5, 7),
    16
  )}, 0.2)`;

  const sortedFilters = item.filters
    ? [...item.filters].sort((a, b) => a.displayOrder - b.displayOrder)
    : [];

  return (
    <Dialog
      ref={scope}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'transparent !important',
          boxShadow: 'none !important',
          '--Paper-shadow': 'none !important',
          '--Paper-overlay': 'none !important',
          backgroundImage: 'none !important',
        },
        '& .MuiBackdrop-root': {
          backgroundColor:
            theme.palette.mode === 'dark'
              ? 'rgba(0, 0, 0, 0.7)'
              : 'rgba(0, 0, 0, 0.5)',
        },
      }}
      PaperProps={{
        style: {
          position: isMobile ? 'absolute' : 'relative',
          bottom: isMobile ? 0 : 'auto',
          width: '100%',
          margin: 0,
          borderRadius: isMobile ? '16px 16px 0 0' : '16px',
          maxHeight: '85vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'transparent',
          overflow: 'visible',
        },
      }}
    >
      <motion.div
        id="menu-item-popup"
        ref={drawerRef}
        initial={isMobile ? { y: height || '100%' } : { opacity: 0 }}
        animate={isMobile ? { y: 0 } : { opacity: 1 }}
        transition={{
          ease: 'easeOut',
          duration: 0.3,
        }}
        style={{
          y,
          backgroundColor: theme.palette.background.paper,
          borderRadius: isMobile ? '16px 16px 0 0' : '16px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          maxHeight: '85vh',
          boxShadow:
            theme.palette.mode === 'dark'
              ? '0px 5px 15px rgba(0, 0, 0, 0.3)'
              : '0px 5px 15px rgba(0, 0, 0, 0.2)',
        }}
        drag={isMobile ? 'y' : false}
        dragControls={controls}
        dragListener={false}
        dragConstraints={{ top: 0, bottom: 0 }}
        dragElastic={0.2}
        dragMomentum={false}
        onDrag={(_, info) => {
          if (info.offset.y > 0) {
            y.set(info.offset.y);
          } else {
            y.set(0);
          }
        }}
        onDragEnd={(_, info) => {
          if (info.offset.y > 100) {
            handleClose();
          } else {
            animate(
              '#menu-item-popup',
              {
                y: 0,
              },
              {
                duration: 0.2,
                ease: 'easeOut',
              }
            );
          }
        }}
      >
        <DialogContent
          sx={{
            padding: '0 !important',
            borderRadius: isMobile ? '16px 16px 0 0' : '16px',
            direction: 'rtl',
            flexGrow: 1,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'primary.main',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-track': {
              display: 'none',
            },
            '& img': {
              width: '100%',
              display: 'block',
              marginRight: '0',
              marginLeft: '0',
            },
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            {/* Draggable area - everything above the divider */}
            <div
              style={{
                touchAction: isMobile ? 'none' : 'auto',
                cursor: isMobile ? 'grab' : 'default',
              }}
              onPointerDown={(e) => {
                if (isMobile) {
                  controls.start(e);
                }
              }}
            >
              <IconButton
                edge="start"
                onClick={handleClose}
                aria-label="close"
                style={{
                  position: 'absolute',
                  left: 26,
                  top: 16,
                  fontSize: '20px',
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  borderRadius: '50%',
                  padding: '4px',
                  zIndex: 1,
                }}
              >
                <CloseIcon />
              </IconButton>
              {item.image && (
                <img
                  src={item.image}
                  alt={`תמונה של ${item.name}`}
                  style={{
                    width: '100%',
                    height: '50vw',
                    maxHeight: '300px',
                    objectFit: 'cover',
                    overflowY: 'visible',
                    margin: 0,
                    display: 'block',
                  }}
                  draggable={false}
                />
              )}
              <div style={{ padding: '0 1rem' }}>
                <div>
                  <Box sx={{ display: 'grid' }}>
                    <Typography variant="titleMenuPopup" color="textPrimary">
                      {item.name}
                    </Typography>
                    <Typography variant="descMenuPopup" color="textSecondary">
                      {item.description}
                    </Typography>
                    <Typography variant="menuPricePopup" color="primary">
                      {`₪${item.price.toFixed(2)}`}
                    </Typography>
                  </Box>
                  <Divider
                    orientation="horizontal"
                    variant="middle"
                    sx={{
                      margin: '1rem 0',
                      backgroundColor: theme.palette.divider,
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ paddingBottom: '3rem' }}>
              {sortedFilters.length > 0 &&
                sortedFilters.map((filter) => (
                  <Grid
                    flexDirection={'column'}
                    padding={'0 1rem 1rem 1rem'}
                    key={filter.id}
                  >
                    <FormControl
                      component="fieldset"
                      key={filter.id}
                      style={{ width: '100%' }}
                    >
                      <FormLabel
                        component="legend"
                        sx={{
                          color: theme.palette.text.primary,
                          '&.Mui-focused': {
                            color: theme.palette.text.primary,
                          },
                        }}
                      >
                        <Typography variant="menuName">
                          {filter.name}
                        </Typography>
                      </FormLabel>
                      {filter.isMultiSelect ? (
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          {filter.options.map((option) => (
                            <div
                              key={option.id}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                direction: 'ltr',
                                height: '2.5rem',
                              }}
                            >
                              <Typography
                                variant="descMenuPopup"
                                color="textSecondary"
                                style={{
                                  flex: 1,
                                  textAlign: 'left',
                                }}
                              >
                                +₪{option.price}
                              </Typography>

                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      selectedFilters
                                        .find((f) => f.filterId === filter.id)
                                        ?.options.some(
                                          (opt) =>
                                            opt.filterOptionId === option.id
                                        ) || false
                                    }
                                    onChange={() =>
                                      handleFilterChange(
                                        filter.id,
                                        option.id,
                                        filter.name,
                                        filter.isMultiSelect,
                                        option.price,
                                        option.name,
                                        option.sku
                                      )
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    variant="descMenuPopup"
                                    color="textPrimary"
                                  >
                                    {option.name}
                                  </Typography>
                                }
                                labelPlacement="start"
                                sx={{
                                  margin: '0',
                                  flexGrow: 1,
                                  justifyContent: 'flex-start',
                                  textAlign: 'right',
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <RadioGroup
                          value={
                            selectedFilters.find(
                              (f) => f.filterId === filter.id
                            )?.options[0]?.filterOptionId || ''
                          }
                          onChange={(e) => {
                            const selectedOption = filter.options.find(
                              (opt) => opt.id === e.target.value
                            );
                            handleFilterChange(
                              filter.id,
                              selectedOption.id,
                              filter.name,
                              filter.isMultiSelect,
                              selectedOption.price,
                              selectedOption.name,
                              selectedOption.sku
                            );
                          }}
                        >
                          {filter.options.map((option) => (
                            <div
                              key={option.id}
                              style={{
                                height: '2.5rem',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                direction: 'ltr',
                              }}
                            >
                              <Typography
                                variant="descMenuPopup"
                                color="textSecondary"
                                style={{
                                  flex: 1,
                                  textAlign: 'left',
                                }}
                              >
                                +₪{option.price}
                              </Typography>

                              <FormControlLabel
                                value={option.id}
                                control={
                                  <Radio
                                    sx={{
                                      padding: '6px',
                                      color: theme.palette.primary.main,
                                      '&.Mui-checked': {
                                        color: theme.palette.primary.main,
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="descMenuPopup"
                                    color="textPrimary"
                                  >
                                    {option.name}
                                  </Typography>
                                }
                                labelPlacement="start"
                                sx={{
                                  margin: '0',
                                  flexGrow: 1,
                                  justifyContent: 'flex-start',
                                  textAlign: 'right',
                                }}
                              />
                            </div>
                          ))}
                        </RadioGroup>
                      )}

                      {showValidation &&
                        filter.isMandatory &&
                        !validationResults[filter.id] && (
                          <Typography variant="body2" color="error">
                            שדה חובה
                          </Typography>
                        )}
                    </FormControl>
                  </Grid>
                ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            padding: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            boxSizing: 'border-box',
            backgroundColor: theme.palette.background.paper,
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            borderTop: `1px solid ${theme.palette.divider}`,
            zIndex: 1200,
            borderRadius: isMobile ? '16px 16px 0 0' : '16px',
          }}
        >
          <div style={{ flex: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddToCart}
              disabled={shouldDisplayRedMessage} // Disable the button if out of stock
              style={{
                borderRadius: '12px',
                width: '100%',
                color: shouldDisplayRedMessage
                  ? theme.palette.action.disabled
                  : theme.palette.primary.contrastText, // Use disabled color if unavailable
                height: '48px',
                fontSize: '1rem',
                boxShadow: shouldDisplayRedMessage ? 'none' : theme.shadows[2], // Remove shadow if unavailable
                backgroundColor: shouldDisplayRedMessage
                  ? theme.palette.action.disabledBackground // Use theme's disabled background color
                  : theme.palette.primary.main,
                cursor: shouldDisplayRedMessage ? 'not-allowed' : 'pointer', // Change cursor if unavailable
                opacity: shouldDisplayRedMessage ? 0.8 : 1, // Light opacity to reinforce "out of stock"
              }}
            >
              <Typography variant="menuPricePopup">
                {shouldDisplayRedMessage
                  ? globals.notInStockMessage
                  : fromCart
                  ? `עדכן הזמנה ₪${totalPrice}`
                  : `הוסף להזמנה ₪${totalPrice}`}
              </Typography>
            </Button>
          </div>
          <div
            style={{
              flex: 1,
              borderRadius: '12px',
              backgroundColor: quantityBackgroundColor,
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '48px',
              boxShadow: theme.shadows[2],
            }}
          >
            <IconButton
              onClick={handleQuantityIncrease}
              style={{ height: '100%', color: theme.palette.text.primary }}
            >
              <AddIcon />
            </IconButton>

            <Typography
              variant="menuPricePopup"
              style={{ margin: '0 16px', color: theme.palette.text.primary }}
            >
              {quantity}
            </Typography>
            <IconButton
              onClick={handleQuantityDecrease}
              style={{ height: '100%', color: theme.palette.text.primary }}
            >
              <RemoveIcon />
            </IconButton>
          </div>
        </DialogActions>
      </motion.div>
    </Dialog>
  );
};

export default MenuItemPopup;

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { selectConfiguration } from './features/configuration/configurationSlice';
import { createAppTheme } from './styles/theme';
import AccessibilityButton from './components/Accessibility/AccessibilityButton';
import AppRoutes from './AppRoutes';
import { useInitialData } from './hooks/useInitialData';
import { getCookie, setCookie } from './utils/cookies';
import * as globals from './utils/globals';
import { getSunTimes } from './api/darkModeTimesApi';
import { fetchManagerMenuItems } from './features/menu/managerMenuThunks';
import { fetchMenuItems } from './features/menu/menuThunks';
import { fetchCategories } from './features/category/CategoryThunk';
//import { fetchWorkingHours } from './features/workingHours/workingHoursThunk';
import { fetchCoupons } from './features/coupons/couponsThunk';
import Loader from './components/siteComponents/utils/Loader';
import { AuthProvider } from './auth';
import { selectMenu } from './features/menu/menuSlice';
import { selectManagerMenu } from './features/menu/managerMenuSlice';

// App content component
function AppContent() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoading: isConfigLoading, error } = useInitialData();
  const configurations = useSelector(selectConfiguration);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [isRouteDataLoading, setIsRouteDataLoading] = useState(true);
  const [sunTimes, setSunTimes] = useState({
    sunSetTime: globals.lightTimeEnd,
    sunRiseTime: globals.lightTimeStart,
  });
  const managerMenuItems = useSelector(selectManagerMenu);
  const menuItems = useSelector(selectMenu);

  // Fetch common data (categories, working hours, coupons)
  useEffect(() => {
    const fetchCommonData = async () => {
      try {
        await Promise.all([
          dispatch(fetchCategories()),
          //dispatch(fetchWorkingHours()), // no need, already being fetched in useInitialData
          dispatch(fetchCoupons()),
        ]);
        setIsInitialDataLoaded(true);
      } catch (error) {
        console.error('Error fetching common data:', error);
      }
    };

    if (!isInitialDataLoaded) {
      fetchCommonData();
    }
  }, [dispatch, isInitialDataLoaded]);

   // Handle route-specific data fetching
   useEffect(() => {
    const fetchRouteData = async () => {
      setIsRouteDataLoading(true);
      try {
        if (location.pathname === '/menu' && menuItems.length === 0) {
          await dispatch(fetchMenuItems());
        } else if (location.pathname === '/dashboard' && managerMenuItems.length === 0) {
          await dispatch(fetchManagerMenuItems());
        }
      } catch (error) {
        console.error('Error fetching route data:', error);
      } finally {
        setIsRouteDataLoading(false);
      }
    };

    // Only fetch route data if common data is loaded
    if (isInitialDataLoaded) {
      fetchRouteData();
    }
  }, [dispatch, location.pathname, isInitialDataLoaded, menuItems.length, managerMenuItems.length]);

  ////////////////////////////////////////// Dark Mode Start //////////////////////////////////////////

  // helper function to convert time string to minutes
  const timeToMinutes = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const getCurrentTimeInMinutes = () => {
    const now = new Date();
    return now.getHours() * 60 + now.getMinutes();
  };

  // Fetch sunrise and sunset times from API and set them in state
  useEffect(() => {
    const fetchSunTimes = async () => {
      try {
        const times = await getSunTimes(
          globals.israelLatitude,
          globals.israelLongitude
        );
        if (times) {
          setSunTimes({
            sunSetTime: times.sunSetTime,
            sunRiseTime: times.sunRiseTime,
          });
        }
      } catch (error) {
        console.error('Error setting sun times:', error);
      }
    };

    fetchSunTimes();
  }, []);

  const isNightTime = useCallback(() => {
    const currentMinutes = getCurrentTimeInMinutes();
    const sunsetMinutes = timeToMinutes(sunTimes.sunSetTime);
    const sunriseMinutes = timeToMinutes(sunTimes.sunRiseTime);
    return currentMinutes >= sunsetMinutes || currentMinutes < sunriseMinutes;
  }, [sunTimes]);

  const getDarkModeState = useCallback(
    (darkModeConfig) => {
      const cookieDarkMode = getCookie('darkMode');
      if (cookieDarkMode !== null) {
        return cookieDarkMode === 'true';
      }

      switch (darkModeConfig) {
        case 0:
          return false;
        case 1:
          return true;
        case 2:
          return isNightTime();
        case 3:
          return window.matchMedia('(prefers-color-scheme: dark)').matches;
        default:
          return false;
      }
    },
    [isNightTime]
  );

  const [isDarkMode, setIsDarkMode] = useState(() =>
    getDarkModeState(configurations?.[0]?.darkMode)
  );

  const [theme, setTheme] = useState(() =>
    createAppTheme(isDarkMode ? 'dark' : 'light', configurations[0] || {})
  );

  useEffect(() => {
    const cookieDarkMode = getCookie('darkMode');
    if (cookieDarkMode !== null) return;

    if (configurations?.[0]?.darkMode === 3) {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handleChange = (e) => setIsDarkMode(e.matches);
      mediaQuery.addEventListener('change', handleChange);
      return () => mediaQuery.removeEventListener('change', handleChange);
    }
  }, [configurations]);

  useEffect(() => {
    const checkCookieAndUpdate = () => {
      const cookieDarkMode = getCookie('darkMode');
      return cookieDarkMode !== null;
    };

    if (!checkCookieAndUpdate() && configurations?.[0]?.darkMode === 2) {
      const checkTime = () => {
        if (!checkCookieAndUpdate()) {
          setIsDarkMode(isNightTime());
        }
      };

      const interval = setInterval(checkTime, 60000);
      checkTime();
      return () => clearInterval(interval);
    }
  }, [configurations, isNightTime]);

  useEffect(() => {
    if (configurations?.[0]) {
      setTheme(
        createAppTheme(isDarkMode ? 'dark' : 'light', configurations[0])
      );
    }
  }, [isDarkMode, configurations]);

  useEffect(() => {
    const cookieDarkMode = getCookie('darkMode');
    if (cookieDarkMode !== null) return;

    const newDarkMode = getDarkModeState(configurations?.[0]?.darkMode);
    setIsDarkMode(newDarkMode);
  }, [configurations, getDarkModeState]);

  const setThemeWithoutCookie = useCallback((newMode) => {
    setIsDarkMode(newMode);
  }, []);

  const toggleTheme = useCallback(() => {
    setIsDarkMode((prev) => {
      const newMode = !prev;
      setCookie('darkMode', String(newMode), globals.darkModeAccessibilityTime);
      return newMode;
    });
  }, []);

  ////////////////////////////////////////// Dark Mode End //////////////////////////////////////////

  // Add this effect after other useEffect hooks
  useEffect(() => {
    if (configurations?.[0]?.placeName) {
      document.title = configurations[0].placeName;
    }
  }, [configurations]);

  // Show loader while either initial data or route-specific data is loading
  if (isConfigLoading || !isInitialDataLoaded || isRouteDataLoading) {
    return <Loader />; //s
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        p={3}
      >
        <Alert severity="error">
          Error loading application data. Please refresh the page or try again
          later.
        </Alert>
      </Box>
    );
  }

  // Render the app content
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRoutes />
      <AccessibilityButton
        toggleTheme={toggleTheme}
        setThemeWithoutCookie={setThemeWithoutCookie}
        isDarkMode={isDarkMode}
        darkModeConfig={configurations?.[0]?.darkMode}
        isNightTime={isNightTime()}
      />
    </ThemeProvider>
  );
}

// App component
function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;

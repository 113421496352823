import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  Paper,
  TextField,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ErrorIcon from '@mui/icons-material/Error';
import RadioButton from '../components/dashboardComponents/StoreSettings/EmployeeFormComponents/RadioButton';
import Textarea from '../components/dashboardComponents/StoreSettings/EmployeeFormComponents/Textarea';
import MultiselectCheckbox from '../components/dashboardComponents/StoreSettings/EmployeeFormComponents/MultiselectCheckbox';

const EmployeeForm = () => {
  // State management
  const [formFields, setFormFields] = useState([]);
  const [formTitle, setFormTitle] = useState('');
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isTitleTouched, setTitleTouched] = useState(false);
  const navigate = useNavigate();

  /*   // Initialize form with default email field
  useEffect(() => {
    const defaultEmailField = {
      id: 'responder_email',
      type: 'email',
      label: 'כתובת האימייל שלך',
      required: true,
      isFixed: true,
      order: 0,
    };

    setFormFields([defaultEmailField]);
    
  }, []); */

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Add new field
  const addField = (type) => {
    const newField = {
      id: Date.now(),
      type,
      label: `שאלה ${formFields.length}`,
      options: type !== 'text' ? ['אפשרות 1', 'אפשרות 2'] : [],
      required: false,
      order: formFields.length,
    };
    setFormFields([...formFields, newField]);
  };

  // Update field label
  const updateFieldLabel = (id, newLabel) => {
    setFormFields(
      formFields.map((field) =>
        field.id === id ? { ...field, label: newLabel } : field
      )
    );
  };

  // Update field options
  const updateFieldOptions = (id, newOptions) => {
    setFormFields(
      formFields.map((field) =>
        field.id === id ? { ...field, options: newOptions } : field
      )
    );
  };

  // Handle required toggle
  const handleToggleRequired = (id, isRequired) => {
    setFormFields(
      formFields.map((field) =>
        field.id === id ? { ...field, required: isRequired } : field
      )
    );
  };

  // Handle field deletion
  const handleDeleteField = (id) => {
    if (id === 'responder_email') {
      showSnackbar('לא ניתן למחוק את שדה האימייל', 'error');
      return;
    }
    setFormFields(formFields.filter((field) => field.id !== id));
  };

  // Handle form save
  const handleSaveForm = () => {
    if (!formTitle.trim()) {
      showSnackbar('נא להזין כותרת לטופס', 'error');
      return;
    }

    try {
      const formData = {
        id: Date.now(),
        title: formTitle,
        fields: formFields,
        createdAt: new Date().toISOString(),
      };

      // Save to localStorage
      const existingForms = JSON.parse(
        localStorage.getItem('employee_forms') || '[]'
      );
      existingForms.push(formData);
      localStorage.setItem('employee_forms', JSON.stringify(existingForms));

      // Reset form keeping the email field
      setFormTitle('');
      setFormFields([formFields[0]]); // Keep the email field
      showSnackbar('הטופס נשמר בהצלחה!');
    } catch (error) {
      console.error('Error saving form:', error);
      showSnackbar('שגיאה בשמירת הטופס', 'error');
    }
  };

  // Render field based on type
  const renderField = (field) => {
    const commonProps = {
      field: field,
      updateFieldLabel: updateFieldLabel,
      onDeleteField: handleDeleteField,
      onToggleRequired: handleToggleRequired,
    };

    const requiredIndicator = field.required && (
      <ErrorIcon sx={{ color: 'error.main', mr: 1, fontSize: 20 }} />
    );

    const fieldWrapper = (content) => (
      <Box key={field.id} sx={{ mb: 3 }}>
        <Paper
          elevation={2}
          sx={{
            p: 3,
            borderRight: 4,
            borderColor: field.required ? 'error.main' : 'primary.main',
            position: 'relative',
          }}
        >
          {field.type === 'email' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                justifyContent: 'right',
              }}
            >
              <Typography
                variant="subtitle1"
                color={field.required ? 'error.main' : 'primary.main'}
              >
                {field.label}
                {requiredIndicator}
              </Typography>
            </Box>
          )}
          {content}
        </Paper>
      </Box>
    );

    switch (field.type) {
      /* case 'email':
        return fieldWrapper(
          <TextField
            type="email"
            required
            fullWidth
            disabled={field.isFixed}
            placeholder="כתובת האימייל שלך"
            sx={{
              '& .MuiInputBase-input': {
                textAlign: 'right',
                padding: '14px',
              },
              '& .MuiInputBase-root': {
                flexDirection: 'row-reverse',
              },
              '& .MuiFormHelperText-root': {
                textAlign: 'right',
                marginRight: '14px',
                marginLeft: 0,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                textAlign: 'right',
              },
            }}
            helperText="כתובת זו תשמש לזיהוי שליחת הטופס שלך"
          />
        ); */
      case 'text':
        return fieldWrapper(<Textarea {...commonProps} />);
      case 'radio':
        return fieldWrapper(
          <RadioButton
            {...commonProps}
            updateFieldOptions={updateFieldOptions}
          />
        );
      case 'multiple':
        return fieldWrapper(
          <MultiselectCheckbox
            {...commonProps}
            updateFieldOptions={updateFieldOptions}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        pb: '80px', // Add padding at the bottom to account for the fixed save button
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flex: 1 }}>
        {/* Form Title */}
        <Box
          sx={{
            mb: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate('/config/forms')}
          >
            חזור לניהול הטפסים
          </Button>
          <Typography variant="h4" gutterBottom sx={{ textAlign: 'right' }}>
            יוצר טפסי עובדים
          </Typography>
        </Box>

        <TextField
          fullWidth
          variant="outlined"
          value={formTitle}
          onBlur={() => setTitleTouched(true)}
          onChange={(e) => setFormTitle(e.target.value)}
          required
          error={isTitleTouched && !formTitle.trim()}
          placeholder="כותרת הטופס"
          sx={{
            mt: 2,
            mb: 12.5, // Increased from 3.5 to create more space below
            minHeight: '0.1rem', // Reduced to bring error message closer
            '& .MuiInputBase-input': {
              textAlign: 'right',
              padding: '14px',
            },
            '& .MuiInputBase-root': {
              flexDirection: 'row-reverse',
            },
            '& .MuiFormHelperText-root': {
              textAlign: 'right',
              marginRight: '14px',
              marginLeft: 0,
              position: 'absolute',
              bottom: '-22px', // Adjusted to be closer to the input
              right: 0,
              left: 'auto',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              textAlign: 'right',
            },
          }}
          slotProps={{
            startAdornment: !formTitle.trim() && (
              <Typography
                component="span"
                sx={{
                  color: 'error.main',
                  marginLeft: '8px',
                  fontSize: '0.875rem',
                }}
              ></Typography>
            ),
          }}
          helperText={!formTitle.trim() ? 'נדרש להזין כותרת לטופס' : ''}
        />

        {/* Form Fields */}
        <Box>
          {formFields
            .sort((a, b) => a.order - b.order)
            .map((field) => renderField(field))}
        </Box>

        {/* Add Field Buttons */}
        <Box mb={2} sx={{ display: 'flex', gap: 1, justifyContent: 'right' }}>
          <Button variant="contained" onClick={() => addField('text')}>
            הוסף שדה טקסט
          </Button>
          <Button variant="contained" onClick={() => addField('radio')}>
            הוסף כפתורי רדיו
          </Button>
          <Button variant="contained" onClick={() => addField('multiple')}>
            הוסף בחירה מרובה
          </Button>
        </Box>
      </Box>

      {/* Submit Button */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: 'background.paper',
          p: 2,
          borderTop: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'left',
          zIndex: 1000,
          height: '64px',
          boxShadow: '0px -2px 4px rgba(0,0,0,0.1)',
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={handleSaveForm}
          startIcon={<SaveIcon />}
          disabled={!formTitle || formFields.length < 1}
        >
          שמור טופס
        </Button>
      </Box>

      {/* Notifications */}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmployeeForm;

import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUnifiedFiles,
  selectUnifiedFileStatus,
  selectUnifiedFileError,
} from '../../../features/unifiedFile/unifiedFileSlice';
import { fetchUnifiedFiles } from '../../../features/unifiedFile/unifiedFileThunk';

function GenerateFiles() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [path, setPath] = useState('');
  const dispatch = useDispatch();
  const summaryData = useSelector(selectUnifiedFiles) || [];
  const status = useSelector(selectUnifiedFileStatus);
  const error = useSelector(selectUnifiedFileError);

  const fetchSummaryData = () => {
    dispatch(
      fetchUnifiedFiles({
        startDate,
        endDate,
        path,
      })
    );
  };

  const handlePrint = () => {
    window.print();
  };

  const handlePathSelect = async () => {
    try {
      // Modern approach using File System Access API
      const dirHandle = await window.showDirectoryPicker({
        mode: 'readwrite',
      });
      // Just get the directory name/path without accessing files
      setPath(dirHandle.name);
    } catch (err) {
      // Fallback if File System Access API is not supported
      const userPath = prompt('הכנס נתיב לשמירת הקובץ:', 'C:\\');
      if (userPath) {
        setPath(userPath);
      }
    }
  };

  return (
    <>
      {/* ��וספת CSS מיוחד להדפסה */}
      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }
            #printableTable, #printableTable * {
              visibility: visible;
            }
            #printableTable {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
            }
          }
        `}
      </style>

      <Box sx={{ padding: 4, direction: 'rtl', textAlign: 'right' }}>
        <Typography variant="h5" gutterBottom>
          יצירת קובץ הזמנות במבנה אחיד
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, marginBottom: 3, marginTop: 3 }}>
          <Button
            variant="outlined"
            onClick={handlePathSelect}
            sx={{ flexGrow: 1 }}
          >
            {path || 'בחר תיקיית שמירה'}
          </Button>
          <TextField
            label="תאריך התחלה"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ flexGrow: 1 }}
          />
          <TextField
            label="תאריך סיום"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ flexGrow: 1 }}
          />
          <Button
            variant="contained"
            onClick={fetchSummaryData}
            disabled={!startDate || !endDate || !path || status === 'loading'}
          >
            {status === 'loading' ? 'טוען...' : 'שמור קבצים'}
          </Button>
        </Box>

        {error && (
          <Typography color="error" variant="body2">
            שגיאה: {error}
          </Typography>
        )}

        {summaryData.length > 0 && (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginBottom: 2 }}
              onClick={handlePrint}
            >
              הדפס טבלה
            </Button>
            <div id="printableTable">
              <TableContainer component={Paper} sx={{ direction: 'rtl' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">מספר המסמך</TableCell>
                      <TableCell align="right">סוג המסמך</TableCell>
                      <TableCell align="right">סה"כ כמותי</TableCell>
                      <TableCell align="right">סה"כ כספי (בש"ח)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {summaryData.map((doc, index) => (
                      <TableRow key={index}>
                        <TableCell align="right">{doc.type}</TableCell>
                        <TableCell align="right">{doc.description}</TableCell>
                        <TableCell align="right">{doc.quantity}</TableCell>
                        <TableCell align="right">
                          {doc.amount.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
      </Box>
    </>
  );
}

export default GenerateFiles;

import React from 'react';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLogout } from '../../../hooks/useLogout';

const DashboardNavbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { handleLogout } = useLogout();

  const handleConfigClick = () => {
    navigate('/config');
  };
  const handleLogoutClick = () => {
    handleLogout();
  };
  const handleDashboardClick = () => {
    navigate('/dashboard');
  };
  const handleOrdersClick = () => {
    navigate('/orders');
  };
  const handleReportsClick = () => {
    navigate('/reports');
  };

  const buttonStyle = {
    fontSize: isSmallScreen ? '1rem' : '1.2rem',
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
    padding: '4px 12px',
    height: '40px',
    whiteSpace: 'nowrap',
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.background.paper
            : '#f3f3f3',
        mb: 2,
        direction: 'rtl',
        boxShadow: theme.palette.mode === 'dark' ? 'none' : undefined,
        borderBottom:
          theme.palette.mode === 'dark'
            ? `1px solid ${theme.palette.divider}`
            : 'none',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 4px !important', // Override MUI's default padding
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            overflowX: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
            '& > button': {
              // Target direct button children
              flex: '0 0 auto', // Prevent buttons from shrinking
            },
          }}
        >
          <Button onClick={handleConfigClick} sx={buttonStyle}>
            הגדרות
          </Button>
          <Button onClick={handleDashboardClick} sx={buttonStyle}>
            תפריט
          </Button>
          <Button onClick={handleOrdersClick} sx={buttonStyle}>
            הזמנות
          </Button>
          <Button onClick={handleReportsClick} sx={buttonStyle}>
            דוחות
          </Button>
        </Box>
        {isSmallScreen ? (
          <IconButton
            onClick={handleLogoutClick}
            sx={{
              color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
            }}
          >
            <LogoutIcon />
          </IconButton>
        ) : (
          <Button
            onClick={handleLogoutClick}
            sx={buttonStyle}
            endIcon={<LogoutIcon sx={{ mr: 1 }} />}
          >
            התנתקות
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  useTheme,
} from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import DraggableItem from '../../core/DraggableItem';

const FilterOrderPopup = ({ open, onClose, filters, onUpdateFilters }) => {
  const [localFilters, setLocalFilters] = useState([]);
  const [initialFilters, setInitialFilters] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const theme = useTheme();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 60,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (open) {
      const copiedFilters = filters.map((filter) => ({
        ...filter,
        options: filter.options || [], // Ensure options array exists
      }));

      setLocalFilters(copiedFilters);
      setInitialFilters(copiedFilters);
    }
  }, [open, filters]);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = localFilters.findIndex(
        (filter) => filter.id === active.id
      );
      const newIndex = localFilters.findIndex(
        (filter) => filter.id === over.id
      );

      const updatedFilters = arrayMove(localFilters, oldIndex, newIndex);

      updatedFilters.forEach((filter, index) => {
        filter.displayOrder = index + 1;
      });

      setLocalFilters(updatedFilters);
    }
    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const handleSave = () => {
    const updatedFilters = localFilters.map((filter, index) => ({
      ...filter,
      displayOrder: index, // Ensure displayOrder is updated based on the new order
    }));

    onUpdateFilters(updatedFilters);
    onClose();
  };

  const handleCancel = () => {
    setLocalFilters(JSON.parse(JSON.stringify(initialFilters)));
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          width: '400px',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DialogTitle style={{ textAlign: 'center', padding: '16px 24px' }}>
        עריכת סדר תצוגת המסננים
      </DialogTitle>
      <DialogContent
        style={{ flex: '1 1 auto', overflowY: 'auto', padding: '20px' }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext
            items={localFilters.map((filter) => filter.id)}
            strategy={verticalListSortingStrategy}
          >
            {localFilters.map((filter) => (
              <DraggableItem
                key={filter.id}
                id={filter.id}
                style={{
                  padding: '12px',
                  marginBottom: '8px',
                  backgroundColor: theme.palette.background.paper,
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                }}
                renderIcon={false}
              >
                {(attributes, listeners) => (
                  <>
                    <span style={{ flex: 1, textAlign: 'right' }}>
                      {filter.name}
                    </span>
                    <div {...attributes} {...listeners} className="drag-icon">
                      <DragIndicator
                        style={{ color: theme.palette.text.secondary }}
                      />
                    </div>
                  </>
                )}
              </DraggableItem>
            ))}
          </SortableContext>

          <DragOverlay>
            {activeId ? (
              <div
                style={{
                  padding: '12px',
                  backgroundColor: theme.palette.background.paper,
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  boxShadow: '0 5px 15px rgba(0,0,0,0.15)',
                  width: 'calc(100% - 24px)',
                  opacity: 0.8,
                }}
              >
                <span style={{ flex: 1, textAlign: 'right' }}>
                  {localFilters.find((filter) => filter.id === activeId)?.name}
                </span>
                <div className="drag-icon">
                  <DragIndicator
                    style={{ color: theme.palette.text.secondary }}
                  />
                </div>
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>
      </DialogContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 24px',
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button onClick={handleCancel} variant="outlined">
          ביטול
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          שמירה
        </Button>
      </div>
    </Dialog>
  );
};

export default FilterOrderPopup;

import React, { useState, useEffect } from 'react';
import { Popover, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DragIndicator } from '@mui/icons-material';
import DraggableItem from '../../core/DraggableItem';

const MenuItemsOrderPopup = ({
  items = [],
  onSaveOrder,
  onClose,
  open,
  anchorEl,
}) => {
  const [localItems, setLocalItems] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const theme = useTheme();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 60,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (Array.isArray(items)) {
      const sortedItems = [...items].sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
      setLocalItems(sortedItems);
    }
  }, [items]);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = localItems.findIndex(item => item.id === active.id);
      const newIndex = localItems.findIndex(item => item.id === over.id);

      const updatedItems = arrayMove(localItems, oldIndex, newIndex);
      
      const itemsWithNewOrder = updatedItems.map((item, index) => ({
        ...item,
        displayOrder: index + 1,
      }));

      setLocalItems(itemsWithNewOrder);
      setHasChanges(true);
    }
    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const handleSave = () => {
    onSaveOrder(localItems);
    onClose();
  };

  const menuItemStyle = {
    padding: '1rem',
    marginBottom: '0.5rem',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };


  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 2,
          minWidth: 300,
        },
      }}
    >
      <div>
        <Typography variant="h6" sx={{ mb: 1, textAlign: 'right' }}>
          סידור מחדש של פריטי הקטגוריה
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, textAlign: 'right' }}>
          גרור פריטים כדי לשנות את סדר התצוגה שלהם
        </Typography>

        <div style={{ marginBottom: '1rem' }}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <SortableContext 
              items={localItems.map(item => item.id)} 
              strategy={verticalListSortingStrategy}
            >
              {localItems.map((item) => (
                <DraggableItem
                  key={item.id}
                  id={item.id}
                  style={menuItemStyle}
                  renderIcon={false}
                >
                  {(attributes, listeners) => (
                    <>
                      <div {...attributes} {...listeners} className="drag-icon" style={{ order: 0 }}>
                        <DragIndicator style={{ color: theme.palette.text.secondary }} />
                      </div>
                      <span style={{ flexGrow: 1, textAlign: 'right' }}>{item.name}</span>
                    </>
                  )}
                </DraggableItem>
              ))}
            </SortableContext>

            <DragOverlay>
              {activeId ? (
                <div style={menuItemStyle}>
                  <div className="drag-icon" style={{ order: 0 }}>
                    <DragIndicator style={{ color: theme.palette.text.secondary }} />
                  </div>
                  <span style={{ flexGrow: 1, textAlign: 'right' }}>
                    {localItems.find(item => item.id === activeId)?.name}
                  </span>
                </div>
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>

        <div
          style={{ display: 'flex', justifyContent: 'flex-start', gap: '1rem' }}
        >
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={!hasChanges}
          >
            שמירה
          </Button>
          <Button onClick={onClose} variant="outlined">
            ביטול
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default MenuItemsOrderPopup;
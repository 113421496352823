import { createSlice } from '@reduxjs/toolkit';
import {
  addFilterOptionThunk,
  fetchFilterOptionsByFilterThunk,
  updateFilterOptionThunk,
  deleteFilterOptionThunk,
} from './filterOptionThunk';

const filterOptionsSlice = createSlice({
  name: 'filterOptions',
  initialState: {
    byFilterId: {},
    status: 'idle',
    error: null,
  },
  reducers: {
    addFilterOption(state, action) {
      if (!state.byFilterId[action.payload.filterId]) {
        state.byFilterId[action.payload.filterId] = [];
      }
      state.byFilterId[action.payload.filterId].push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilterOptionsByFilterThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFilterOptionsByFilterThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.byFilterId[action.meta.arg] = action.payload;
      })
      .addCase(fetchFilterOptionsByFilterThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addFilterOptionThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addFilterOptionThunk.fulfilled, (state, action) => {
        const { filterId } = action.payload;
        if (!state.byFilterId[filterId]) {
          state.byFilterId[filterId] = [];
        }
        // Don't push if already exists
        const exists = state.byFilterId[filterId].some(
          (opt) => opt.id === action.payload.id
        );
        if (!exists) {
          state.byFilterId[filterId].push(action.payload);
        }
      })
      .addCase(updateFilterOptionThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateFilterOptionThunk.fulfilled, (state, action) => {
        const { id, filterId } = action.payload;
        if (!state.byFilterId[filterId]) {
          state.byFilterId[filterId] = [];
        }
        const index = state.byFilterId[filterId].findIndex(
          (option) => option.id === id
        );
        if (index !== -1) {
          state.byFilterId[filterId][index] = action.payload;
        }
      })
      .addCase(deleteFilterOptionThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteFilterOptionThunk.fulfilled, (state, action) => {
        const { id, filterId } = action.meta.arg;
        if (state.byFilterId[filterId]) {
          state.byFilterId[filterId] = state.byFilterId[filterId].filter(
            (option) => option.id !== id
          );
        }
      });
  },
});

export const { addFilterOption } = filterOptionsSlice.actions;

export default filterOptionsSlice.reducer;

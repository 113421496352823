import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Switch,
  IconButton,
  useTheme,
  useMediaQuery,
  Collapse,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { selectWorkingHours } from '../../../features/workingHours/workingHoursSlice';
import {
  updateWorkingHours,
  createWorkingHours,
  deleteWorkingHours,
} from '../../../features/workingHours/workingHoursThunk';
import { alpha } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

const WorkingHoursManager = () => {
  const [editingHours, setEditingHours] = useState(null);
  const [deletedShiftIds, setDeletedShiftIds] = useState(new Set());
  const workingHours = useSelector(selectWorkingHours);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const daysInHebrew = useMemo(
    () => ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'],
    []
  );

  const initializeHours = useCallback(() => {
    const initialHours = daysInHebrew.map((day, index) => {
      const existingHoursForDay = workingHours.filter((h) => h.day === index);
      const defaultShift = {
        openTime: '09:00',
        closeTime: '17:00',
        id: null,
      };

      if (existingHoursForDay.length === 0) {
        return {
          day: index,
          dayName: day,
          shifts: [defaultShift],
          isOpen: false,
        };
      }

      return {
        day: index,
        dayName: day,
        shifts:
          existingHoursForDay.length > 0
            ? existingHoursForDay.map((hours) => ({
                openTime: hours.openTime || defaultShift.openTime,
                closeTime: hours.closeTime || defaultShift.closeTime,
                id: hours.id || null,
              }))
            : [defaultShift],
        isOpen: existingHoursForDay.some((h) => h.isOpen),
      };
    });
    return initialHours;
  }, [daysInHebrew, workingHours]);

  React.useEffect(() => {
    const initializedHours = initializeHours();
    setEditingHours(initializedHours);
  }, [initializeHours]);

  const parseTimeString = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return dayjs().hour(parseInt(hours)).minute(parseInt(minutes));
  };

  const formatTime = (time) => {
    return time.format('HH:mm');
  };

  const handleTimeChange = (dayIndex, shiftIndex, field, value) => {
    if (!value || !value.isValid()) return;

    const updatedHours = [...editingHours];
    updatedHours[dayIndex].shifts[shiftIndex][field] = formatTime(value);
    setEditingHours(updatedHours);
  };

  const handleIsOpenChange = (dayIndex) => {
    const updatedHours = [...editingHours];
    const newIsOpen = !updatedHours[dayIndex].isOpen;
    updatedHours[dayIndex].isOpen = newIsOpen;

    if (updatedHours[dayIndex].shifts.length === 0) {
      updatedHours[dayIndex].shifts = [
        {
          openTime: '09:00',
          closeTime: '17:00',
          id: null,
        },
      ];
    }

    setEditingHours(updatedHours);
  };

  const addShift = (dayIndex) => {
    const updatedHours = [...editingHours];
    updatedHours[dayIndex].shifts.push({
      openTime: '09:00',
      closeTime: '17:00',
      id: null,
    });
    setEditingHours(updatedHours);
  };

  const removeShift = (dayIndex, shiftIndex) => {
    const updatedHours = [...editingHours];
    const shiftToRemove = updatedHours[dayIndex].shifts[shiftIndex];

    // If the shift has an ID, add it to deletedShiftIds for later deletion
    if (shiftToRemove.id) {
      setDeletedShiftIds((prev) => new Set([...prev, shiftToRemove.id]));
    }

    updatedHours[dayIndex].shifts.splice(shiftIndex, 1);
    if (updatedHours[dayIndex].shifts.length === 0) {
      updatedHours[dayIndex].shifts = [
        {
          openTime: '09:00',
          closeTime: '17:00',
          id: null,
        },
      ];
    }
    setEditingHours(updatedHours);
  };

  const handleSave = async () => {
    try {
      const deletionPromises = Array.from(deletedShiftIds).map((id) =>
        dispatch(deleteWorkingHours(id))
      );
      await Promise.all(deletionPromises);

      const updatePromises = editingHours.flatMap((dayHours) =>
        dayHours.shifts.map((shift) => {
          const hoursToUpdate = {
            day: dayHours.day,
            openTime: shift.openTime,
            closeTime: shift.closeTime,
            isOpen: dayHours.isOpen,
          };

          if (shift.id && !deletedShiftIds.has(shift.id)) {
            return dispatch(
              updateWorkingHours({
                id: shift.id,
                workingHour: hoursToUpdate,
              })
            );
          } else if (!shift.id) {
            return dispatch(createWorkingHours(hoursToUpdate));
          }

          return Promise.resolve();
        })
      );

      await Promise.all(updatePromises);
      alert('השינויים נשמרו בהצלחה');
    } catch (error) {
      console.error('Error saving working hours:', error);
      alert('שגיאה בשמירת השינויים');
    }
  };

  const timePickerStyle = {
    width: '100%',
    '& .MuiInputBase-root': {
      cursor: 'pointer',
    },
    '& .MuiInputBase-input': {
      cursor: 'pointer',
      padding: '4px 0 5px',
      textAlign: 'right',
    },
    '& .MuiIconButton-root': {
      padding: '8px',
    },
    '& .MuiInputLabel-root': {
      left: 'auto',
      right: 0,
      transformOrigin: 'right',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, -1.5px) scale(0.75)',
      },
    },
    // Ensure the helper text is also right-aligned
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          p: 3,
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        {editingHours && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
            }}
          >
            {editingHours.map((dayHours, dayIndex) => (
              <Box
                key={dayIndex}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark'
                      ? alpha(theme.palette.background.paper, 0.6)
                      : '#eeeeee',
                  padding: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  mb: 2,
                  direction: 'rtl',
                  width: '100%',
                  minWidth: isMobile ? '100%' : '300px',
                  borderColor: (theme) =>
                    theme.palette.mode === 'dark'
                      ? alpha(theme.palette.divider, 0.1)
                      : 'rgba(0, 0, 0, 0.12)',
                }}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '4rem 1fr',
                    alignItems: 'center',
                    width: '100%',
                    borderBottom: (theme) =>
                      `1px solid ${
                        theme.palette.mode === 'dark'
                          ? alpha(theme.palette.divider, 0.2)
                          : 'rgba(0, 0, 0, 0.12)'
                      }`,
                    pb: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '1.2rem',
                      fontWeight: 600,
                      width: '120px',
                      color: (theme) => theme.palette.text.primary,
                    }}
                  >
                    {dayHours.dayName}:
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography color="text.primary">
                      {dayHours.isOpen ? 'פתוח' : 'סגור'}
                    </Typography>
                    <Switch
                      checked={dayHours.isOpen}
                      onChange={() => handleIsOpenChange(dayIndex)}
                    />
                  </Box>
                </Box>

                <Collapse in={dayHours.isOpen}>
                  <Box sx={{ pl: 4 }}>
                    {dayHours.shifts.map((shift, shiftIndex) => (
                      <Box
                        key={shiftIndex}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Typography sx={{ color: 'text.secondary' }}>
                            משמרת {shiftIndex + 1}:
                          </Typography>
                          {dayHours.shifts.length > 1 && (
                            <IconButton
                              onClick={() => removeShift(dayIndex, shiftIndex)}
                              color="error"
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 2,
                            flexDirection: isMobile ? 'column' : 'row',
                            width: '100%',
                            alignItems: isMobile ? 'stretch' : 'center',
                          }}
                        >
                          <Box sx={{ position: 'relative', width: '100%' }}>
                            <DesktopTimePicker
                              label="שעת פתיחה"
                              value={parseTimeString(shift.openTime)}
                              onChange={(newValue) =>
                                handleTimeChange(
                                  dayIndex,
                                  shiftIndex,
                                  'openTime',
                                  newValue
                                )
                              }
                              ampm={false}
                              timeSteps={{ minutes: 1 }}
                              slotProps={{
                                textField: {
                                  variant: 'standard',
                                  sx: timePickerStyle,
                                },
                              }}
                            />
                          </Box>

                          <Box sx={{ position: 'relative', width: '100%' }}>
                            <DesktopTimePicker
                              label="שעת סגירה"
                              value={parseTimeString(shift.closeTime)}
                              onChange={(newValue) =>
                                handleTimeChange(
                                  dayIndex,
                                  shiftIndex,
                                  'closeTime',
                                  newValue
                                )
                              }
                              ampm={false}
                              timeSteps={{ minutes: 1 }}
                              slotProps={{
                                textField: {
                                  variant: 'standard',
                                  sx: timePickerStyle,
                                },
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ))}

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        cursor: 'pointer',
                        color: 'primary.main',
                        '&:hover': {
                          opacity: 0.8,
                          color: (theme) => theme.palette.primary.light,
                        },
                        mt: 1,
                      }}
                      onClick={() => addShift(dayIndex)}
                    >
                      <AddIcon fontSize="small" />
                      <Typography>הוסף משמרת נוספת</Typography>
                    </Box>
                  </Box>
                </Collapse>
              </Box>
            ))}
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mt: 4,
          }}
        >
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{ minWidth: '200px' }}
          >
            שמור שינויים
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default WorkingHoursManager;

// Reports.js
import React from 'react';
import GenerateFiles from '../components/dashboardComponents/StoreSettings/UnifiedFile.js';
import { Box } from '@mui/material';
import DashboardNavbar from '../components/dashboardComponents/utils/DashboardNavbar.js';

function Reports() {
  return (
    <Box>
      <DashboardNavbar />
      <GenerateFiles />
    </Box>
  );
}

export default Reports;
